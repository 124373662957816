import Action from '@/gf/components/Action'
import AddressInput from '@/gf/components/next/forms/AddressInput'
import Field from '@/gf/components/next/forms/Field'
import PhoneInput from '@/gf/components/next/forms/PhoneInput'
import TextInput from '@/gf/components/next/forms/TextInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useWatch } from 'react-hook-form'
import * as Yup from 'yup'
import GoogleBusinessTypeahead from './GoogleBusinessTypeahead'
import { CustomerForm } from './types'

const validationSchema = Yup.object().shape({
  accountNumber: Yup.string().required('Account Number is required'),
  companyName: Yup.string().required('Company Name is required'),
  companyPhoneNumber: Yup.string().required('Phone Number is required'),
  address: Yup.object().nullable(),
  contactName: Yup.string().required('Contact Name is required'),
  contactEmail: Yup.string().email('Must be a valid email address').required('Email is required'),
  contactPhoneNumber: Yup.string().label('Phone Number'),
})

const Form = ({
  onNext,
  performing,
}: {
  performing?: boolean
  onNext: (values: CustomerForm) => void
}) => {
  const form = useForm<CustomerForm>({
    defaultValues: {
      companyName: '',
      companyPhoneNumber: '',
      address: null,
      contactName: '',
      contactEmail: '',
      contactPhoneNumber: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const address = useWatch({
    name: 'address',
    control: form.control,
    defaultValue: null,
  })

  return (
    <form onSubmit={form.handleSubmit(onNext)} className="space-y-6">
      <div className="space-y-2">
        <Controller
          control={form.control}
          name="companyName"
          render={({ field, fieldState }) => (
            <Field label="Company Name" error={fieldState.error?.message}>
              <GoogleBusinessTypeahead
                value={field.value}
                onChange={field.onChange}
                onDetailsFound={(details) => {
                  if (details.address) {
                    form.setValue('address', details.address, { shouldValidate: true })
                  }
                  if (details.name) {
                    form.setValue('companyName', details.name, { shouldValidate: true })
                  }
                  if (details.phoneNumber) {
                    form.setValue('companyPhoneNumber', details.phoneNumber, {
                      shouldValidate: true,
                    })
                    form.setValue('contactPhoneNumber', details.phoneNumber, {
                      shouldValidate: true,
                    })
                  }
                }}
              />
            </Field>
          )}
        />

        <div className="grid grid-cols-2 gap-2">
          <Field label="Account Number" error={form.formState.errors.accountNumber?.message}>
            <TextInput {...form.register('accountNumber')} />
          </Field>

          <Controller
            control={form.control}
            name="companyPhoneNumber"
            render={({ field, fieldState }) => (
              <Field label="Company Phone" error={fieldState.error?.message}>
                <PhoneInput value={field.value} onChange={field.onChange} />
              </Field>
            )}
          />
        </div>

        <Controller
          control={form.control}
          name="address"
          render={({ field }) => (
            <Field error={form.formState.errors.address?.message}>
              <AddressInput
                address={address}
                hideCompany
                hideFirstLastName
                onChange={field.onChange}
              />
            </Field>
          )}
        />
      </div>

      <hr />

      <div className="space-y-2">
        <h3 className="pb-2">Your Account Representative</h3>
        <Field label="Full Name" error={form.formState.errors.contactName?.message}>
          <TextInput {...form.register('contactName')} />
        </Field>

        <div className="grid grid-cols-2 gap-x-2">
          <Controller
            control={form.control}
            name="contactPhoneNumber"
            render={({ field, fieldState }) => (
              <Field label="Phone Number" error={fieldState.error?.message}>
                <PhoneInput value={field.value} onChange={field.onChange} />
              </Field>
            )}
          />

          <Field label="Email Address" error={form.formState.errors.contactEmail?.message}>
            <TextInput {...form.register('contactEmail')} />
          </Field>
        </div>
      </div>

      <Action.P
        type="submit"
        className="w-full font-medium"
        performing={performing}
        disabled={performing}
      >
        Confirm
      </Action.P>
    </form>
  )
}

export default Form
