import { useClaimAccountMutation } from '@/dealers/_gen/gql'
import useReloadSession from '@/dealers/hooks/useReloadSession'
import useSession from '@/dealers/hooks/useSession'
import ClaimAccountModalC from '@/gf/components/ClaimAccount/Modal'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

const ClaimAccountModal = ({
  open,
  onClose,
  customHeader,
  cancelButtonEnabled = false,
}: {
  open: boolean
  onClose: () => void
  customHeader?: ReactNode
  cancelButtonEnabled?: boolean
}) => {
  const reloadSession = useReloadSession()
  const { user } = useSession()
  const [claimAccountMutation] = useClaimAccountMutation()
  const navigate = useNavigate()

  const onSuccess = async () => {
    await reloadSession()
    navigate('/setup/welcome')
  }

  return (
    <ClaimAccountModalC
      open={open}
      onClose={onClose}
      onSuccess={onSuccess}
      email={user.email}
      claimAccount={(values) => claimAccountMutation({ variables: values })}
      customHeader={customHeader}
      cancelButtonEnabled={cancelButtonEnabled}
      app="sales-hub"
    />
  )
}

export default ClaimAccountModal
