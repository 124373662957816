import Tag from '@/gf/components/Reports/Tag'
import { ResponsiveBar } from '@nivo/bar'
import partition from 'lodash/partition'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { formatMoneyInt, total } from '../OpenQuotes'
import { useContext } from './Context'

type ResponsiveBarProps = Parameters<typeof ResponsiveBar>[0]
type TooltipProps = Parameters<Exclude<ResponsiveBarProps['tooltip'], undefined>>[0]

const blues = ['#60a5fa', '#2563eb', '#1e40af', '#172554']
const primaryColor = blues[0]
const secondaryColor = blues[1]
const tertiaryColor = blues[2]
const quaternaryColor = blues[3]
const gray700 = 'rgb(55, 65, 81)'

const Chart = () => {
  const { storeOrders, orgId, orgById, days, update } = useContext()

  const Tooltip = useCallback(
    ({ id }: TooltipProps) => (
      <div className="bg-white rounded-md shadow-sm text-gray-700 px-3 py-2">
        {id === 'openQuotes' ? (
          orgId ? (
            <>Other Open Quotes</>
          ) : (
            <>Open Quotes</>
          )
        ) : (
          <>Customer&apos;s Open Quotes</>
        )}
      </div>
    ),
    [orgId]
  )

  if (!storeOrders) return null

  const colors = {
    openQuotes: primaryColor,
    customerOpenQuotes: secondaryColor,
  }

  const selectedBorderColors = {
    openQuotes: tertiaryColor,
    customerOpenQuotes: quaternaryColor,
  }

  const textColors = {
    openQuotes: 'white',
    customerOpenQuotes: 'white',
  }

  const now = DateTime.now()
  const sevenDaysAgo = now.minus({ days: 7 })
  const thirtyDaysAgo = now.minus({ days: 30 })
  const sixtyDaysAgo = now.minus({ days: 60 })

  const [orgStoreOrders, restStoreOrders] = orgId
    ? partition(storeOrders, (so) => so.customer.organization.id === orgId)
    : [[], storeOrders]

  const [restLessThanSeven, restSevenOrMore] = partition(
    restStoreOrders,
    (so) => so.originatedAt > sevenDaysAgo
  )

  const [restSevenToThirty, restThirtyOrMore] = partition(
    restSevenOrMore,
    (so) => so.originatedAt > thirtyDaysAgo
  )

  const [restThirtyToSixty, restSixtyOrMore] = partition(
    restThirtyOrMore,
    (so) => so.originatedAt > sixtyDaysAgo
  )

  const [orgLessThanSeven, orgSevenOrMore] = partition(
    orgStoreOrders,
    (so) => so.originatedAt > sevenDaysAgo
  )

  const [orgSevenToThirty, orgThirtyOrMore] = partition(
    orgSevenOrMore,
    (so) => so.originatedAt > thirtyDaysAgo
  )

  const [orgThirtyToSixty, orgSixtyOrMore] = partition(
    orgThirtyOrMore,
    (so) => so.originatedAt > sixtyDaysAgo
  )

  const data = [
    {
      days: '< 7',
      openQuotes: total(restLessThanSeven).amount,
      customerOpenQuotes: total(orgLessThanSeven).amount,
    },
    {
      days: '7 - 30',
      openQuotes: total(restSevenToThirty).amount,
      customerOpenQuotes: total(orgSevenToThirty).amount,
    },
    {
      days: '30 - 60',
      openQuotes: total(restThirtyToSixty).amount,
      customerOpenQuotes: total(orgThirtyToSixty).amount,
    },
    {
      days: '> 60',
      openQuotes: total(restSixtyOrMore).amount,
      customerOpenQuotes: total(orgSixtyOrMore).amount,
    },
  ]

  return (
    <div className="w-full h-full flex flex-col gap-y-6">
      <div className="w-full h-10 flex justify-end items-center gap-x-2">
        {days && <Tag onRemove={() => update({ days: null })}>{days} Days Open</Tag>}

        {orgId && (
          <Tag onRemove={() => update({ orgId: null })}>{orgById && orgById[orgId].name}</Tag>
        )}
      </div>

      <div className="w-full h-full z-[1000]">
        <ResponsiveBar
          data={data}
          keys={['customerOpenQuotes', 'openQuotes']}
          indexBy="days"
          margin={{ top: 3, right: 0, bottom: 50, left: 0 }}
          colors={(datum) => colors[datum.id]}
          theme={{
            text: { fontSize: 16, fill: gray700 },
            axis: { legend: { text: { fontSize: 16, fill: gray700 } } },
          }}
          axisLeft={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Days Open',
            legendPosition: 'middle',
            legendOffset: 40,
            truncateTickAt: 0,
          }}
          enableGridX={false}
          enableGridY={false}
          borderColor={(datum) =>
            days === datum.data.indexValue
              ? selectedBorderColors[datum.data.id]
              : colors[datum.data.id]
          }
          borderWidth={days ? 4 : 0}
          borderRadius={4}
          tooltip={Tooltip}
          labelTextColor={(datum) => textColors[datum.data.id]}
          label={(datum) => (datum.value ? formatMoneyInt(datum.value) : '')}
          onClick={(datum) => {
            const newDays = String(datum.indexValue)
            update({ days: newDays !== days ? newDays : null })
          }}
        />
      </div>
    </div>
  )
}

export default Chart
