import { useAddUserMutation } from '@/dealers/_gen/gql'
import ModalForm from '@/gf/components/ModalForm'
import PhoneInput from '@/gf/components/PhoneInput'
import TextField from '@/gf/components/TextField'
import useMsgs from '@/gf/hooks/useMsgs'
import { ModalSize } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Must be a valid email address').required('Email is required'),
  phone: Yup.string().required('Phone Number is required'),
})

const AddUserModal = ({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}) => {
  const [_, msgs] = useMsgs()
  const [addUser, { loading: saveInProgress }] = useAddUserMutation()

  const form = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (values) => {
    try {
      await addUser({ variables: values })

      msgs.add(`User ${values.name} added to store`, 'positive')

      form.reset()
      onClose()

      onSuccess()
    } catch (error) {
      const err = error as { graphQLErrors: { message: string }[] }
      const message = err.graphQLErrors.reduce((acc, { message: msg }) => `${acc}${msg}`, '')
      msgs.add(message, 'negative')
    }
  }

  return (
    <ModalForm
      open={open}
      onClose={onClose}
      title="Add User"
      size={ModalSize.SM}
      onSubmit={form.handleSubmit(onSubmit)}
      submitButtonShowSpinner={saveInProgress}
    >
      <div className="space-y-4">
        <TextField
          {...form.register('name')}
          label="Name"
          errorText={form.formState.errors.name?.message}
        />

        <TextField
          {...form.register('email')}
          label="Email"
          errorText={form.formState.errors.email?.message}
        />

        <Controller
          name="phone"
          control={form.control}
          render={({ field, formState }) => (
            <PhoneInput
              value={field.value}
              onChange={field.onChange}
              errorText={formState.errors.phone?.message}
            />
          )}
        />
      </div>
    </ModalForm>
  )
}

export default AddUserModal
