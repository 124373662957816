import { PhoneIcon } from '@heroicons/react/solid'
import debounce from 'lodash/debounce'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CustomerContactsListQuery, useCustomerContactsListQuery } from '../_gen/gql'

import useSession from '@/dealers/hooks/useSession'

import Phone from '@/gf/modules/Phone'
import Urls from '../modules/Urls'

import A from '@/gf/components/A'
import Ghost from '@/gf/components/Ghost'
import Link from '@/gf/components/Link'
import Page from '@/gf/components/Page'
import Pagination from '@/gf/components/Pagination'
import SearchInput from '@/gf/components/SearchInput'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import Tabs from '@/gf/components/Tabs'
import { trackSearch } from '@/gf/modules/Analytics'
import Frame from '../components/Frame'
import FullPagePaywall from '../components/PayWall/FullPagePaywall'
import SendQuoteButton from '../components/SendQuoteButton'

const Row = ({
  contact,
  sendQuoteButton,
}: {
  contact: CustomerContactsListQuery['customersContacts']['entries'][number]
  sendQuoteButton: boolean
}) => (
  <Tr>
    <Td>
      <span className="block font-medium">{contact.name}</span>
      <span className="block text-xs text-slate-500 italic mt-0.5">{contact.email}</span>
    </Td>
    <Td>
      {contact.phoneNumber && (
        <A.T href={`tel:${contact.phoneNumber}`} className="font-medium">
          <PhoneIcon className="h-4 inline-block text-gearflow" />
          {Phone.format(contact.phoneNumber)}
        </A.T>
      )}
    </Td>

    <Td>
      <Link.T to={Urls.customerAccount(contact.account.id)} className="font-medium capitalize">
        {contact.account.name}
      </Link.T>
      {contact.account.accountNumbers.length > 0 && (
        <span className="block text-xs text-slate-500 italic mt-1">
          Account # {contact.account.accountNumbers.join(', ')}
        </span>
      )}
    </Td>
    {sendQuoteButton && (
      <Td>
        <SendQuoteButton customerContactId={contact.id} />
      </Td>
    )}
  </Tr>
)

const LoadingRows = () => (
  <>
    {[1, 2].map((v) => (
      <Tr key={v}>
        <Td>
          <span className="flex flex-col gap-y-1.5">
            <Ghost className="h-5 w-56" />
            <Ghost className="h-4 w-40" />
          </span>
        </Td>
        <Td>
          <Ghost className="block h-5 w-20" />
        </Td>
        <Td>
          <span className="flex flex-col gap-y-1.5">
            <Ghost className="h-5 w-40" />
            <Ghost className="h-4 w-24" />
          </span>
        </Td>
        <Td>
          <Ghost className="block h-8 w-32 rounded-lg" />
        </Td>
      </Tr>
    ))}
  </>
)

const NoResultsRow = ({ searchTerm }) => (
  <Tr>
    <Td colSpan={3}>
      {searchTerm ? (
        <p>
          No customers or contacts found for <strong className="font-bold">{searchTerm}</strong>.
        </p>
      ) : (
        <p>No customers found.</p>
      )}
    </Td>
  </Tr>
)

const CustomerContacts = () => {
  const { store } = useSession()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const { data, loading } = useCustomerContactsListQuery({
    variables: { page, filters: searchTerm ? [['search', searchTerm]] : [] },
    fetchPolicy: 'no-cache',
    onCompleted: (resultData) => {
      if (searchTerm) {
        trackSearch(searchTerm, 'customer_contacts_page', {
          resultsFound: resultData.customersContacts.pagination.totalResults,
        })
      }
    },
  })
  const contacts = data?.customersContacts.entries ?? []

  const debouncedSearchChanged = useRef(debounce(setSearchTerm, 300)).current

  const showPaywall = !store.proSubscription

  return (
    <Frame
      breadcrumbs={{
        copy: 'Back to Dashboard',
        crumbs: [
          { name: 'Customers', href: Urls.customerAccounts() },
          { name: 'Contacts', href: Urls.customerContacts() },
        ],
      }}
    >
      {showPaywall ? (
        <FullPagePaywall title="Customers" />
      ) : (
        <Page
          title="Customers"
          actionsNext={[
            <Link.S to={Urls.newAccount()} key="add-customer">
              Add Customer
            </Link.S>,
          ]}
        >
          <Tabs
            tabs={[{ name: 'Accounts' }, { name: 'Contacts' }]}
            onTabSelect={(tabName) => tabName === 'Accounts' && navigate(Urls.customerAccounts())}
            currentTabName="Contacts"
          />

          <div className="self-start w-full min-w-full rounded border bg-gray-50 mt-4">
            <div className="pt-6 pb-2 px-6">
              <SearchInput
                initialValue={searchTerm}
                onChange={debouncedSearchChanged}
                placeholder="search by contact name..."
              />
            </div>

            <div className="w-full">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Phone</Th>
                    <Th>Account</Th>
                    <Th className="w-32" />
                  </Tr>
                </Thead>
                <Tbody>
                  {loading ? (
                    <LoadingRows />
                  ) : contacts.length === 0 ? (
                    <NoResultsRow searchTerm={searchTerm} />
                  ) : (
                    contacts.map((contact) => (
                      <Row key={contact.id} contact={contact} sendQuoteButton />
                    ))
                  )}
                </Tbody>
              </Table>
            </div>
          </div>

          <div className="mt-2">
            <Pagination
              pagination={data?.customersContacts.pagination}
              page={page}
              updatePage={setPage}
            />
          </div>
        </Page>
      )}
    </Frame>
  )
}

export default CustomerContacts
