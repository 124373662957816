import { useRfqAssignToUserMutation, useStoreUsersListQuery } from '@/dealers/_gen/gql'
import ClaimAccountModal from '@/dealers/components/ClaimAccount/Modal'
import Action from '@/gf/components/Action'
import Ghost from '@/gf/components/Ghost'
import UserInitials from '@/gf/components/UserInitials'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import useOnClickOutside from '@/gf/hooks/useClickOutside'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { Maybe } from '@/types'
import classNames from 'classnames'
import { useRef } from 'react'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

const Loading = () => (
  <>
    {[1, 2, 3].map((i) => (
      <li key={i} className="border-b border-slate-200 p-2 flex gap-x-2">
        <Ghost className={classNames('h-4 block w-24')} />
        <Ghost
          className={classNames('h-4 block', {
            'w-2/5': i === 1,
            'w-1/2': i === 2,
            'w-1/4': i === 3,
          })}
        />
      </li>
    ))}
  </>
)

const AssignUser = ({
  rfqId,
  assignedUser,
  onAssignedUserChanged,
  claimedAccount,
}: {
  rfqId: string
  assignedUser: Maybe<{ id: string; name: string }>
  onAssignedUserChanged: () => void
  claimedAccount: boolean
}) => {
  const [assignOwnerInitial, setAssignOwnerParam] = useQueryParam(
    'assignOwner',
    withDefault(BooleanParam, false)
  )
  const [_, msgs] = useMsgs()
  const { data, loading } = useStoreUsersListQuery({ fetchPolicy: 'network-only' })
  const [assignMutation] = useRfqAssignToUserMutation()
  const [claimModalOpen, claimModalToggle] = useToggle()
  const [userListOpen, userListToggle] = useToggle(assignOwnerInitial)
  const assignButtonRef = useRef(null)

  const dismissUserList = () => {
    if (assignOwnerInitial === true) {
      setAssignOwnerParam(undefined, 'replace')
    }

    userListToggle.off()
  }

  useOnClickOutside(assignButtonRef, dismissUserList)

  const selectableUsers = (data?.session.store?.users ?? []).filter((u) =>
    assignedUser ? assignedUser.id !== u.id : true
  )

  const assignToUser = async (owner: { id: string; name: string }) => {
    try {
      await assignMutation({ variables: { rfqId, ownerId: owner.id, replace: true } })

      dismissUserList()
      onAssignedUserChanged()

      msgs.add(`Request assigned to ${owner.name}`, 'positive')
    } catch (err) {
      console.error(err)
      msgs.add('Could not assign request', 'negative')
    }
  }

  return (
    <>
      <div ref={assignButtonRef}>
        <Tooltip open={userListOpen} placement="bottom-end">
          <TooltipTrigger onClick={userListToggle.toggle}>
            {assignedUser ? (
              <Tooltip placement="bottom-end">
                <TooltipTrigger>
                  <UserInitials name={assignedUser.name} size="sm" />
                </TooltipTrigger>
                <TooltipContent className="max-w-40 p-2 z-[5000] bg-gray-700 border border-gray-900 rounded shadow-sm text-xs text-white text-center">
                  <p>Owner: {assignedUser.name}</p>
                  {!claimedAccount && (
                    <p className="mt-1">
                      To reassign this quote, you will first need to claim your free account.
                    </p>
                  )}
                </TooltipContent>
              </Tooltip>
            ) : (
              <Action.T>Assign</Action.T>
            )}
          </TooltipTrigger>
          <TooltipContent className="max-w-72 z-[5000] bg-white rounded-md border border-gray-200 shadow-md overflow-hidden mt-1">
            {claimedAccount ? (
              <ul>
                {loading ? (
                  <Loading />
                ) : selectableUsers.length === 0 ? (
                  <p className="p-6">You are the only user in this store</p>
                ) : (
                  selectableUsers.map((u) => (
                    <li key={u.id} className="border-b">
                      <button
                        type="button"
                        onClick={() => assignToUser(u)}
                        className="h-full w-full flex items-center p-2 hover:bg-blue-50 text-left gap-x-2"
                      >
                        <UserInitials name={u.name} size="sm" />
                        <span className="text-sm flex-shrink-0">{u.name}</span>
                        <span className="text-xs text-gray-600 truncate">{u.email}</span>
                      </button>
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <div className="max-w-[17rem] p-6 flex flex-col items-center gap-y-3">
                <p className="text-center text-base">
                  To reassign this quote to someone on your team, you will first need to claim your
                  free account.
                </p>
                <Action.P onClick={claimModalToggle.on} className="font-medium">
                  Claim Account
                </Action.P>
              </div>
            )}
          </TooltipContent>
        </Tooltip>
      </div>

      <ClaimAccountModal open={claimModalOpen} onClose={claimModalToggle.off} />
    </>
  )
}

export default AssignUser
