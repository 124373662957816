import { useEffect } from 'react'

// Code from: https://innolitics.com/articles/web-form-warn-on-nav/
const handleEvent = (e: BeforeUnloadEvent) => {
  e.preventDefault()

  // This custom message will only be used in older browsers
  const message = 'Your changes may not be saved.'

  e.returnValue = message
  return message
}

const useUnloadConfirmation = (shouldPrompt: boolean) => {
  const eventName = 'beforeunload'
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shouldPrompt) {
      window.addEventListener(eventName, handleEvent)

      return () => {
        window.removeEventListener(eventName, handleEvent)
      }
    }
  }, [shouldPrompt])
}

export default useUnloadConfirmation
