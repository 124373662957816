import { Address } from '@/types'
import { AddressInput } from '../_gen/gql'

const addressToAddressInput = (address: Address): AddressInput => ({
  id: null,
  lineOne: address.lineOne ?? null,
  lineTwo: address.lineTwo ?? null,
  city: address.city ?? null,

  state: address.state ?? null,
  country: address.country ?? null,
  postalCode: address.postalCode ?? null,
  point: address.point
    ? {
        lat: address.point.lat,
        lng: address.point.lng,
      }
    : address.latitude && address.longitude
    ? {
        lat: address.latitude,
        lng: address.longitude,
      }
    : null,

  companyName: address.companyName ?? null,
  firstName: address.firstName ?? null,
  lastName: address.lastName ?? null,

  deliverable: address.deliverable ?? null,
  rdi: address.rdi ?? null,
})

export default { addressToAddressInput }
