import { Route, Routes } from 'react-router-dom'

import {
  SubscriptionQueryResult,
  useCreateProSubscriptionMutation,
  useStoreUsersListQuery,
  useSubscriptionQuery,
} from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import useReloadSession from '@/dealers/hooks/useReloadSession'
import MoneyM from '@/gf/modules/Money'

import Box from '@/gf/components/Box'
import Spinner from '@/gf/components/Spinner'
import RedAlert from '@/gf/components/RedAlert'
import Frame from '../components/Frame'
import Page from '@/gf/components/Page'
import Subscribe from '@/gf/components/PlanAndBilling'
import Showcase from '../components/PayWall/Showcase'

const SubscribeWrapper = ({ onSubscribed }: { onSubscribed: () => Promise<unknown> }) => {
  const { store } = useSession()
  const { data } = useStoreUsersListQuery()
  const [createProSubscription] = useCreateProSubscriptionMutation()
  const users = data?.session.store?.users || []

  const minLicenses = data?.session.store?.users.length || 1
  const licenseUnitPrice = MoneyM.fromDecimal(100, 'USD')

  return (
    <Subscribe
      title="Upgrade to Sales Hub Pro"
      users={users}
      minLicenses={minLicenses}
      baseLicenses={null}
      basePrice={MoneyM.fromInt(0, 'USD')}
      email={store.email}
      licenseUnitPrice={licenseUnitPrice}
      createProSubscription={createProSubscription}
      onSubscribed={onSubscribed}
    />
  )
}

const SubscribeRouter = ({ onSubscribed }: { onSubscribed: () => Promise<unknown> }) => (
  <Routes>
    <Route
      path=""
      element={
        <div>
          <Showcase />
        </div>
      }
    />
    <Route path="subscribe" element={<SubscribeWrapper onSubscribed={onSubscribed} />} />
  </Routes>
)

const format = (value) => MoneyM.formatNoCents(MoneyM.fromDecimal(value / 100, 'USD'))

const SubscriptionDetails = ({
  subscription: { stripePlan, frequency, licenses, customerEmail, amount },
}: {
  subscription: NonNullable<NonNullable<SubscriptionQueryResult['data']>['proSubscription']>
}) => {
  const { data } = useStoreUsersListQuery()
  return (
    <>
      <Box className="p-8 flex flex-col gap-y-6">
        <Box className="p-6 flex flex-col gap-y-3 bg-gray-50">
          <div className="flex flex-col">
            <span className="text-sm font-medium text-gray-500">Current Plan</span>
            <span className="text-2xl font-medium text-orange-500">Sales Hub Pro</span>
          </div>
          <div className="max-w-3xl grid grid-cols-3 divide-x divide-gray-200">
            <div className="pr-6 flex flex-col">
              <span className="text-xs font-medium text-gray-500">Licenses</span>
              <span className="text-xl font-medium text-gray-900">{licenses}</span>
              {data?.session.store && (
                <>
                  <span className="mt-4 text-xs text-gray-900">
                    {data.session.store.users.length}/{licenses} used
                  </span>
                </>
              )}
            </div>
            {stripePlan?.subscriptionType === 'shp_fixed_plus' ? (
              <div className="px-6 flex flex-col">
                <span className="text-xl font-medium text-gray-900">
                  {format(amount)}/
                  {frequency === 'monthly' ? 'month' : frequency === 'yearly' ? 'year' : frequency}
                  {' per user'}
                </span>
              </div>
            ) : (
              <div className="px-6 flex flex-col">
                <span className="text-xl font-medium text-gray-900">
                  {format(amount)}/
                  {frequency === 'monthly' ? 'month' : frequency === 'yearly' ? 'year' : frequency}
                </span>
              </div>
            )}
            <div className="pl-6 flex flex-col gap-y-1">
              <span className="text-xs font-medium text-gray-500">Payment Details</span>
              <span className="block">
                <span className="text-lg block font-medium leading-5">Send Invoice</span>
                <span
                  title={customerEmail}
                  className="text-xs block leading-5 truncate overflow-ellipsis"
                >
                  {customerEmail}
                </span>
              </span>
            </div>
          </div>
        </Box>
      </Box>
    </>
  )
}

const PlanAndBilling = () => {
  const { store } = useSession()
  const { data, error, refetch } = useSubscriptionQuery({ variables: { storeId: store.id } })
  const reloadSession = useReloadSession()

  return (
    <Frame>
      <Page className="pt-4 lg:pt-8 max-w-[66.5rem]">
        {error ? (
          <RedAlert title="Error, please contact support." />
        ) : !data ? (
          <Spinner />
        ) : data.proSubscription ? (
          <SubscriptionDetails subscription={data.proSubscription} />
        ) : (
          <SubscribeRouter onSubscribed={() => Promise.all([refetch(), reloadSession()])} />
        )}
      </Page>
    </Frame>
  )
}

export default PlanAndBilling
