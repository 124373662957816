import classNames from 'classnames'
import GfSwitch from '../Switch'

const Switch = ({
  checked,
  onChange,
  showStateText = false,
  className,
}: {
  checked: boolean
  onChange: (newValue: boolean) => void
  showStateText?: boolean
  className?: string
}) => (
  <span className={classNames('inline-flex gap-x-2 items-center', className)}>
    {showStateText ? (
      <span className={classNames('text-xs', checked ? 'text-gray-900' : 'text-gray-400')}>
        {checked ? 'On' : 'Off'}
      </span>
    ) : null}
    <GfSwitch checked={checked} onChange={onChange} checkedClassName="bg-blue-600" />
  </span>
)

export default Switch
