import Link from '@/gf/components/Link'
import A from '@/gf/components/A'
import Urls from '@/dealers/modules/Urls'

const ActionButtons = () => (
  <div className="flex gap-x-4 flex-shrink-0">
    <A.S
      href="https://meetings.hubspot.com/peter-raisch/gearflow-demo"
      target="_blank"
      size="lg"
      className="w-1/2 font-medium text-blue-500 flex items-center justify-center flex-shrink-0"
    >
      Book Demo
    </A.S>
    <Link.P
      to={Urls.upgradeToPro()}
      size="lg"
      className="w-1/2 font-medium flex items-center justify-center flex-shrink-0"
    >
      Upgrade to Pro
    </Link.P>
  </div>
)

export default ActionButtons
