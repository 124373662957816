import { useOrderCompletedMutation } from '@/dealers/_gen/gql'
import GfCompleteOrder from '@/gf/components/CompleteOrder'
import useMsgs from '@/gf/hooks/useMsgs'
import { useNavigate } from 'react-router-dom'
import { useRfqContext } from '../context'

const CompleteOrder = () => {
  const [_, msgr] = useMsgs()
  const { rfq, refetch } = useRfqContext()
  const [orderCompleted] = useOrderCompletedMutation()
  const navigate = useNavigate()
  const close = () => navigate(`/rfqs/${rfq.id}`)
  const { quote } = rfq

  if (!quote) return null

  const submit = async () => {
    await orderCompleted({ variables: { storeOrderId: quote.id } })
    refetch()
    msgr.add('Order completed.', 'positive')
  }

  const shippedQtyByLineItemId = quote.shipments.reduce<Record<string, number>>(
    (acc, shipment) =>
      shipment.items.reduce(
        (subAcc, item) => ({
          ...subAcc,
          [item.lineItemId]: (subAcc[item.lineItemId] || 0) + item.quantity,
        }),
        acc
      ),
    {}
  )

  const unshippedItems = quote.lineItems
    .map((li) => {
      const unshippedQty = li.quantity - (shippedQtyByLineItemId[li.id] || 0)
      return { ...li, unshippedQty }
    })
    .filter((li) => li.unshippedQty > 0)

  return <GfCompleteOrder unshippedItems={unshippedItems} submit={submit} close={close} />
}

export default CompleteOrder
