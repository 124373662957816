import { useUpdateStoreInfoMutation, useUpdateStorePoliciesMutation } from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import Box from '@/gf/components/Box'
import useMsgs from '@/gf/hooks/useMsgs'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import OneLineAddress from '@/buyers/components/OneLineAddress'
import AddressM from '@/dealers/modules/Address'
import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import TextAreaField from '@/gf/components/TextAreaField'
import TextField from '@/gf/components/TextField'
import { Address } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import ChangeStoreLogo from './Organization/ChangeStoreLogo'
import Section from './Section'
import SectionSeparator from './SectionSeparator'

const aboutValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is Required'),
  description: Yup.string(),
  address: Yup.object().nullable(),
})

const policiesValidationSchema = Yup.object().shape({
  returnPolicy: Yup.string().optional(),
  shippingInfo: Yup.string().optional(),
})

const Organization = () => {
  const [_, msgr] = useMsgs()
  const { store } = useSession()
  const [updateInfo, { loading: updateInfoInProgress }] = useUpdateStoreInfoMutation()
  const [updatePolicies, { loading: updatePoliciesInProgress }] = useUpdateStorePoliciesMutation()

  const storeInfoForm = useForm({
    defaultValues: {
      name: store?.name ?? '',
      description: store?.description ?? '',
      address: store?.address as Address,
    },
    resolver: yupResolver(aboutValidationSchema),
  })

  const storePoliciesForm = useForm({
    defaultValues: {
      returnPolicy: store?.returnPolicy ?? '',
      shippingInfo: store?.shippingInfo ?? '',
    },
    resolver: yupResolver(policiesValidationSchema),
  })

  const onSubmitStoreInfo = async (values) => {
    try {
      await updateInfo({
        variables: {
          name: values.name,
          description: values.description,
          address: values.address ? AddressM.addressToAddressInput(values.address) : null,
        },
      })
      msgr.add('Store updated', 'positive')
    } catch (err) {
      msgr.add('Something went wrong', 'negative')
      console.error(err)
    }
  }

  const onSubmitStorePolicies = async (values) => {
    try {
      await updatePolicies({
        variables: values,
      })
      msgr.add('Store updated', 'positive')
    } catch (err) {
      msgr.add('Something went wrong', 'negative')
      console.error(err)
    }
  }

  if (!store) {
    return <p>store not found</p>
  }

  return (
    <Box className="w-full max-w-screen-md p-6">
      <Section
        title="About"
        subtitle="This information is communicated to your customers in the quoting process."
      >
        <ChangeStoreLogo />

        <form onSubmit={storeInfoForm.handleSubmit(onSubmitStoreInfo)} className="space-y-4">
          <TextField
            label="Name"
            {...storeInfoForm.register('name')}
            errorText={storeInfoForm.formState.errors.name?.message}
          />

          <TextAreaField
            label="About"
            {...storeInfoForm.register('description')}
            errorText={storeInfoForm.formState.errors.name?.message}
          />

          <Controller
            name="address"
            control={storeInfoForm.control}
            render={({ field }) => (
              <Field label="Address" errorText={storeInfoForm.formState.errors.address?.message}>
                <OneLineAddress
                  onAddressSelected={field.onChange}
                  onAddressRemoved={() => field.onChange(null)}
                  value={field.value}
                  onAddressNotFound={(address) => console.error('address not found:', address)}
                />
              </Field>
            )}
          />

          <div className="pt-2">
            <Action.P type="submit" performing={updateInfoInProgress} className="w-40">
              Save
            </Action.P>
          </div>
        </form>
      </Section>

      <SectionSeparator />

      <Section
        title="Policies"
        subtitle="These are the policies that customers agree to when purchasing from your organization"
      >
        <form
          onSubmit={storePoliciesForm.handleSubmit(onSubmitStorePolicies)}
          className="space-y-4"
        >
          <TextAreaField
            label="Return and Warranty Policy"
            {...storePoliciesForm.register('returnPolicy')}
            errorText={storePoliciesForm.formState.errors.returnPolicy?.message}
          />

          <TextAreaField
            label="Shipping Info"
            {...storePoliciesForm.register('shippingInfo')}
            errorText={storePoliciesForm.formState.errors.shippingInfo?.message}
          />

          <div className="pt-2">
            <Action.P type="submit" performing={updatePoliciesInProgress} className="w-40">
              Save
            </Action.P>
          </div>
        </form>
      </Section>
    </Box>
  )
}

export default Organization
