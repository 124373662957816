import classNames from 'classnames'
import debounce from 'lodash/debounce'
import nth from 'lodash/nth'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CustomerAccountStatus, CustomersListQuery, useCustomersListQuery } from '../_gen/gql'

import useSession from '@/dealers/hooks/useSession'

import Urls from '../modules/Urls'

import Ghost from '@/gf/components/Ghost'
import Link from '@/gf/components/Link'
import Page from '@/gf/components/Page'
import Pagination from '@/gf/components/Pagination'
import SearchInput from '@/gf/components/SearchInput'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import Tabs from '@/gf/components/Tabs'
import Tooltip from '@/gf/components/Tooltip'
import { trackSearch } from '@/gf/modules/Analytics'
import AddCustomerModal from '../components/AddCustomerModal/AddCustomerModal'
import ContactSelector from '../components/ContactSelector'
import Frame from '../components/Frame'
import InlineAddress from '../components/InlineAddress'
import FullPagePaywall from '../components/PayWall/FullPagePaywall'
import SendQuoteButton from '../components/SendQuoteButton'

const Bullet = ({ className }) => (
  <span className={classNames('inline-block rounded-full h-4 w-4 mt-1', className)} />
)

const ActiveBullet = () => (
  <Tooltip text="Activated in Gearflow">
    <Bullet className="bg-emerald-500" />
  </Tooltip>
)

const PendingBullet = () => (
  <Tooltip text="Account pending activation">
    <Bullet className="bg-yellow-400" />
  </Tooltip>
)

const Row = ({
  customer,
  sendQuoteButton,
}: {
  customer: CustomersListQuery['customers']['entries'][number]
  sendQuoteButton: boolean
}) => {
  const [selectedContactId, setSelectedContactId] = useState(nth(customer.contacts, 0)?.id)

  return (
    <Tr>
      <Td>
        <span className="flex gap-x-2">
          {customer.status === CustomerAccountStatus.Active ? <ActiveBullet /> : <PendingBullet />}

          <span>
            <Link.T to={Urls.customerAccount(customer.id)} className="font-medium capitalize">
              {customer.name}
            </Link.T>
            {customer.accountNumbers.length > 0 && (
              <span className="block text-xs text-slate-500 italic mt-1">
                Account # {customer.accountNumbers.join(', ')}
              </span>
            )}
          </span>
        </span>
      </Td>
      <Td>
        {customer.address && (
          <InlineAddress
            lineOne={customer.address.lineOne}
            city={customer.address.city}
            state={customer.address.state}
            postalCode={customer.address.postalCode}
          />
        )}
      </Td>
      <Td>
        {selectedContactId ? (
          <ContactSelector
            contacts={customer.contacts}
            selectedContactId={selectedContactId}
            onChange={setSelectedContactId}
          />
        ) : (
          <span className="text-slate-400 italic">None</span>
        )}
      </Td>
      {sendQuoteButton && (
        <Td>
          <SendQuoteButton customerContactId={selectedContactId} />
        </Td>
      )}
    </Tr>
  )
}

const LoadingRows = () => (
  <>
    {[1, 2].map((v) => (
      <Tr key={v}>
        <Td>
          <span className="flex flex-col gap-y-1.5">
            <Ghost className="h-5 w-56" />
            <Ghost className="h-4 w-40" />
          </span>
        </Td>
        <Td>
          <span className="flex flex-col gap-y-1.5">
            <Ghost className="h-5 w-40" />
            <Ghost className="h-4 w-24" />
          </span>
        </Td>
        <Td>
          <span className="flex flex-col gap-y-1.5">
            <Ghost className="h-5 w-40" />
            <Ghost className="h-4 w-24" />
          </span>
        </Td>
        <Td>
          <Ghost className="block h-8 w-32 rounded-lg" />
        </Td>
      </Tr>
    ))}
  </>
)

const NoResultsRow = ({ searchTerm }) => (
  <Tr>
    <Td colSpan={4}>
      {searchTerm ? (
        <p>
          No customers found for <strong className="font-bold">{searchTerm}</strong>.
        </p>
      ) : (
        <p>No customers found.</p>
      )}
    </Td>
  </Tr>
)

const CustomerAccounts = ({ addCustomerOpen = false }: { addCustomerOpen?: boolean }) => {
  const navigate = useNavigate()
  const { store } = useSession()
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const { data, loading, refetch } = useCustomersListQuery({
    variables: { page, filters: searchTerm ? [['search', searchTerm]] : [] },
    fetchPolicy: 'no-cache',
    onCompleted: (resultData) => {
      if (searchTerm) {
        trackSearch(searchTerm, 'customers_page', {
          resultsFound: resultData.customers.pagination.totalResults,
        })
      }
    },
  })
  const customers = data?.customers.entries ?? []

  const debouncedSearchChanged = useRef(debounce(setSearchTerm, 300)).current

  const showPaywall = !store.proSubscription

  return (
    <Frame
      breadcrumbs={{
        copy: 'Back to Dashboard',
        crumbs: [{ name: 'Customers', href: Urls.customerAccounts() }],
      }}
    >
      <AddCustomerModal
        open={addCustomerOpen}
        onClose={() => navigate(Urls.customerAccounts(), { replace: true })}
        onContactSelected={() => {
          navigate(Urls.customerAccounts(), { replace: true })
          refetch()
        }}
      />
      {showPaywall ? (
        <FullPagePaywall title="Customers" />
      ) : (
        <Page
          title="Customers"
          actionsNext={[
            <Link.S to={Urls.newAccount()} key="add-customer">
              Add Customer
            </Link.S>,
          ]}
        >
          <Tabs
            tabs={[{ name: 'Accounts' }, { name: 'Contacts' }]}
            onTabSelect={(tabName) => tabName === 'Contacts' && navigate(Urls.customerContacts())}
            currentTabName="Accounts"
          />

          <div className="self-start w-full min-w-full border bg-gray-50 mt-4">
            <div className="pt-6 pb-2 px-6">
              <SearchInput
                initialValue={searchTerm}
                onChange={debouncedSearchChanged}
                placeholder="search by customer name..."
              />
            </div>

            <div className="w-full">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Customer</Th>
                    <Th>Address</Th>
                    <Th>Contact</Th>
                    <Th className="w-32" />
                  </Tr>
                </Thead>
                <Tbody>
                  {loading ? (
                    <LoadingRows />
                  ) : customers.length === 0 ? (
                    <NoResultsRow searchTerm={searchTerm} />
                  ) : (
                    customers.map((customer) => (
                      <Row key={customer.id} customer={customer} sendQuoteButton />
                    ))
                  )}
                </Tbody>
              </Table>
            </div>
          </div>

          <div className="mt-2">
            <Pagination pagination={data?.customers.pagination} page={page} updatePage={setPage} />
          </div>
        </Page>
      )}
    </Frame>
  )
}

export default CustomerAccounts
