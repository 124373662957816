import { SortOrder } from '@/dealers/_gen/gql'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { formatShortTimeToQuoteMins, rfqsToMedianTimeToQuoteMins } from '../../Service'
import { useContext } from '../context'

const User = () => {
  const context = useContext()
  const { rfqs, creatorById, breakdownUserSort, update } = context
  const sortField = breakdownUserSort ? breakdownUserSort[0] : 'send-quote'
  const sortOrder = breakdownUserSort ? (breakdownUserSort[1] === 'desc' ? 'desc' : 'asc') : 'desc'
  const rfqsByCreatorId = rfqs && groupBy(rfqs, (rfq) => rfq.quote.creation.creator.id)

  const unsortedRows =
    rfqsByCreatorId &&
    map(rfqsByCreatorId, (subRfqs, creatorId) => ({
      creatorId,
      creatorName: creatorById && creatorById[creatorId].name,
      quoteCount: subRfqs.length,
      medianTimeToQuoteMins: rfqsToMedianTimeToQuoteMins(subRfqs),
    }))

  const orderByField = (() => {
    if (sortField === 'send-quote') return 'medianTimeToQuoteMins'
    if (sortField === 'user') return 'creatorName'
    if (sortField === 'quotes') return 'quoteCount'
    return sortField
  })()

  const rows = unsortedRows && orderBy(unsortedRows, orderByField, sortOrder)

  return (
    <ReportingTable
      data={rows}
      getRowKey={({ creatorId }) => creatorId}
      checkbox={{
        getChecked: ({ creatorId }) => creatorId === context.userId,
        onToggleRow: ({ creatorId }) =>
          update({ userId: creatorId !== context.userId ? creatorId : null }),
        onClear: () => update({ userId: null }),
      }}
      sortBy={{
        sortBy: {
          field: sortField,
          order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
        },
        setSortBy: (sortBy) => update({ breakdownUserSort: sortBy }),
      }}
      columns={[
        { header: 'User', getValue: ({ creatorName }) => creatorName, sortByField: 'user' },
        { header: 'Quotes', getValue: ({ quoteCount }) => quoteCount, sortByField: 'quotes' },
        {
          header: 'Send Quote',
          getValue: ({ medianTimeToQuoteMins }) =>
            medianTimeToQuoteMins !== null ? formatShortTimeToQuoteMins(medianTimeToQuoteMins) : '',
          sortByField: 'send-quote',
        },
      ]}
    />
  )
}

export default User
