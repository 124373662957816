import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react'
import { useRef, useState } from 'react'

interface Option<T> {
  value: string
  data: T
}

interface Props<T> {
  options: Option<T>[]
  value: string
  onChange: (selectedValue: string) => void
  renderOption: (data: T, selected: boolean) => React.ReactNode
  renderValue: (selected: T) => React.ReactNode
  creatable?: boolean
  renderCreateOption?: () => React.ReactNode
  displaySelectedOption?: boolean
}

function SingleSelectDropdownList<T>({
  options,
  value,
  onChange,
  renderOption,
  renderValue,
  creatable = false,
  renderCreateOption,
  displaySelectedOption = false,
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          })
        },
        padding: 10,
      }),
    ],
  })

  const listRef = useRef<Array<HTMLElement | null>>([])

  const click = useClick(context, { event: 'mousedown' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    click,
  ])

  const toggleSelection = (option: Option<T>) => {
    onChange(option.value)
    setIsOpen(false)
  }

  const selectedOption = options.find((o) => value === o.value) ?? null

  return (
    <>
      <div
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        {...getReferenceProps()}
      >
        {selectedOption ? renderValue(selectedOption.data) : 'Select...'}
      </div>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                overflowY: 'auto',
                minWidth: 100,
                outline: 0,
                zIndex: 9999,
              }}
              {...getFloatingProps()}
              className="bg-white rounded-lg shadow-lg border border-slate-50"
            >
              {options
                .filter((o) => (displaySelectedOption ? true : o.value !== value))
                .map((o, i) => (
                  <div
                    key={o.value}
                    ref={(node) => {
                      listRef.current[i] = node
                    }}
                    {...getItemProps({
                      // Handle pointer select.
                      onClick() {
                        toggleSelection(o)
                      },
                      // Handle keyboard select.
                      onKeyDown(event) {
                        if (['Enter', ' '].includes(event.key)) {
                          event.preventDefault()
                          toggleSelection(o)
                        }
                      },
                    })}
                  >
                    {renderOption(o.data, value === o.value)}
                  </div>
                ))}
              {creatable && renderCreateOption && renderCreateOption()}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}

export default SingleSelectDropdownList
