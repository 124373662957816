import { useCustomerContactQuery, useCustomerContactsListQuery } from '@/dealers/_gen/gql'
import Select from '@/gf/components/next/Select'
import { Maybe } from '@/types'
import { useMemo } from 'react'

const createLabel = (contact: { name: string; account: { name: string } }) =>
  `${contact.account.name} - ${contact.name}`

const CustomersDropdown = ({
  value,
  onChange,
  disabled,
}: {
  value: Maybe<string>
  onChange: (newValue: string) => void
  disabled: boolean
}) => {
  const { data, loading, refetch } = useCustomerContactsListQuery({
    variables: {
      page: 1,
      filters: [],
    },
  })

  const { data: selectedContactData } = useCustomerContactQuery({
    variables: { customerContactId: value as string },
    skip: !value,
  })

  const options = useMemo(
    () =>
      data?.customersContacts.entries.map((contact) => ({
        label: createLabel(contact),
        value: contact.id,
      })),
    [data]
  )

  const selectedOption = useMemo(
    () =>
      selectedContactData
        ? {
            label: createLabel(selectedContactData.customerContact),
            value: selectedContactData.customerContact.id,
          }
        : null,
    [selectedContactData]
  )

  return (
    <Select
      options={options}
      value={selectedOption}
      isLoading={loading}
      onInputChange={(searchTerm) => {
        refetch({ filters: [['search', searchTerm]] })
      }}
      onChange={(o) => o && onChange(o.value)}
      placeholder=""
      isDisabled={disabled}
    />
  )
}

export default CustomersDropdown
