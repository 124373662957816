import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import { cn } from '@/gf/modules/utils'
import { Popover, Transition } from '@headlessui/react'
import { CheckIcon, OfficeBuildingIcon, PencilIcon, SelectorIcon } from '@heroicons/react/outline'
import { Fragment, HTMLAttributes, useState } from 'react'
import { Link } from 'react-router-dom'
import FreeProTrialModal from '../FreeProTrialModal'

const Div = (props: HTMLAttributes<HTMLDivElement>) => <div {...props} />

const OrgDetails = ({ isProPlan }: { isProPlan: boolean | undefined }) => {
  const { organization, user, switchOrg } = useSession()
  const [isFreeProTrialModalOpen, setIsFreeProTrialModalOpen] = useState(false)
  const OrgName = user.orgs.length > 1 ? Popover.Button : Div

  return (
    <hgroup className="flex gap-x-2 py-3 items-start group">
      {organization.logoUrl ? (
        <img
          src={organization.logoUrl}
          alt={`${organization.name} logo url`}
          className="inline-block h-10 w-10 object-contain rounded-full bg-white border border-gray-300"
        />
      ) : (
        <Link
          to="/settings/organization"
          className="inline-flex shrink-0 h-10 w-10 rounded-full bg-white border border-gray-300 hover:border-gray-400 hover:bg-gray-50 items-center justify-center group transition duration-200"
        >
          <PencilIcon className="inline-block text-gray-700 h-4 w-4 group-hover:text-gray-900" />
        </Link>
      )}

      <Popover className="relative">
        {({ open: _ }) => (
          <>
            <OrgName className="flex gap-x-2 items-center focus:outline-none focus-visible:ring-2 text-left focus-visible:ring-white/75">
              <div>
                <h1 className="font-medium leading-tight">{organization.name}</h1>

                <p className="text-sm flex gap-x-2 items-center">
                  Parts Hub{' '}
                  {isProPlan === undefined
                    ? ''
                    : isProPlan
                    ? 'Pro'
                    : user.can.updateAccountSettings && (
                        <>
                          <FreeProTrialModal
                            open={isFreeProTrialModalOpen}
                            onClose={() => setIsFreeProTrialModalOpen(false)}
                            onClaimed={() => {
                              setIsFreeProTrialModalOpen(false)
                              return Promise.resolve()
                            }}
                          />

                          <Action.T
                            className="font-medium text-sm"
                            onClick={() => setIsFreeProTrialModalOpen(true)}
                          >
                            Upgrade
                          </Action.T>
                        </>
                      )}
                </p>
              </div>

              {user.orgs.length > 1 && (
                <SelectorIcon className="opacity-40 h-5 w-5 shrink-0 group-hover:opacity-100" />
              )}
            </OrgName>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute mt-4 w-96 bg-white py-2 rounded-xl shadow-lg">
                {user.orgs.map((org) => (
                  <button
                    onClick={() => switchOrg(org.id)}
                    className={cn(
                      'w-full px-6 py-4 flex gap-x-2 items-center text-left',
                      org.id === organization.id ? 'bg-blue-50' : 'hover:bg-blue-50'
                    )}
                    key={org.id}
                    type="button"
                  >
                    {org.logoUrl ? (
                      <img
                        src={org.logoUrl || ''}
                        alt={`${org.name} logo url`}
                        className="h-6 w-6 object-contain rounded-full bg-white border border-gray-300"
                      />
                    ) : (
                      <OfficeBuildingIcon className="h-6 w-6" />
                    )}

                    <div className="grow flex gap-x-2 items-center justify-between">
                      <div className="text-base">{org.name}</div>

                      {org.id === organization.id && (
                        <CheckIcon className="w-6 h-6 text-blue-600" />
                      )}
                    </div>
                  </button>
                ))}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </hgroup>
  )
}

export default OrgDetails
