import MachineIcon from '@/buyers/components/Frame/Icons/MachineIcon'
import useSession from '@/dealers/hooks/useSession'
import Link from '@/gf/components/Link'
import RocketLayout from '@/gf/components/RocketLayout'
import { MessagesIcon } from '@/gf/components/RocketLayout/HeaderActions'
import OrgDetails from '@/gf/components/RocketLayout/OrgDetails'
import { HeaderActions, NavItemGroup } from '@/gf/components/RocketLayout/types'
import { Breadcrumbs } from '@/types'
import {
  ChartSquareBarIcon,
  CogIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  PaperAirplaneIcon,
  TemplateIcon,
  UserIcon,
} from '@heroicons/react/outline'
import { ReactNode, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUnreadMessagesQuery } from '../_gen/gql'
import Urls from '../modules/Urls'
import DevMenu from './Frame/DevMenu'
import BoxIcon from './Frame/Icons/BoxIcon'
import LimitedUserFrame from './Frame/LimitedUserFrame'
import SearchResults from './Frame/SearchResults'

type Props = {
  children: ReactNode
  breadcrumbs?: Breadcrumbs
  className?: string
}

const Frame = ({ children, breadcrumbs, className }: Props) => {
  const navigate = useNavigate()
  const { user, featureFlags, fullExperience, store } = useSession()
  const { data, startPolling, stopPolling } = useUnreadMessagesQuery({ fetchPolicy: 'no-cache' })
  const unreadMessagesCount = data?.conversations.pagination.totalResults ?? 0

  useEffect(() => {
    startPolling(30000)
    return () => stopPolling()
  }, [])

  const navItems: NavItemGroup[] = useMemo(
    () => [
      {
        name: 'main',
        items: [
          {
            children: (
              <>
                <TemplateIcon className="h-5 w-5 inline-block " /> Dashboard
              </>
            ),
            href: '/dashboard',
          },

          {
            children: (
              <>
                <PaperAirplaneIcon className="h-5 w-5 inline-block rotate-90" /> Requests
              </>
            ),
            href: '/rfqs',
          },
          ...(featureFlags.salesHubCatalogEnabled
            ? [
                {
                  children: (
                    <>
                      <BoxIcon className="inline-block" />
                      Catalog
                    </>
                  ),
                  href: '/products',
                },
              ]
            : []),
        ],
      },
      {
        name: 'management',
        items: [
          {
            children: (
              <>
                <MachineIcon className="inline-block" /> Customers
              </>
            ),
            href: '/accounts',
          },
          {
            children: (
              <>
                <UserIcon className="h-5 w-5 inline-block" /> Contacts
              </>
            ),
            href: '/customers',
          },
          {
            children: (
              <>
                <ChartSquareBarIcon className="h-5 w-5 inline-block" /> Reports
              </>
            ),
            href: '/reports',
          },
        ],
      },
      {
        name: 'app-settings',
        items: [
          {
            children: (
              <>
                <CogIcon className="h-5 w-5 inline-block" /> Settings
              </>
            ),
            href: '/settings',
          },
          {
            children: (
              <>
                <CreditCardIcon className="h-5 w-5 inline-block" /> Plan & Billing
              </>
            ),
            href: Urls.planAndBilling(),
          },
          {
            children: (
              <>
                <CurrencyDollarIcon className="h-5 w-5 inline-block" /> Wallet
              </>
            ),
            href: '/wallet',
          },
        ],
      },
    ],
    [featureFlags.salesHubCatalogEnabled]
  )

  const headerActions: HeaderActions = [
    {
      label: 'Inbox',
      onClick: () => navigate(Urls.inbox()),
      icon: <MessagesIcon />,
      count: unreadMessagesCount,
    },
  ]

  if (!fullExperience) {
    return <LimitedUserFrame children={children} />
  }

  const subscription = {
    loading: false,
    isProPlan: store.proSubscription,
    productName: 'Sales Hub',
    upgradePlan: (
      <Link.T to={Urls.planAndBilling()} className="font-medium text-sm">
        Upgrade
      </Link.T>
    ),
    upgradePlanHref: Urls.planAndBilling(),
    userCanUpgradePlan: true,
  }

  return (
    <RocketLayout
      orgDetails={
        <OrgDetails
          companyDetails={{
            logoUrl: store.logo?.url ?? null,
            name: store.name,
            updateHref: '/settings/organization',
          }}
          subscriptionDetails={subscription}
        />
      }
      user={{ name: user.name }}
      subscription={subscription}
      navAction={{
        href: '/orders/new',
        label: 'Create Quote',
      }}
      headerActions={headerActions}
      navItems={navItems}
      className={className ?? ''}
      breadcrumbs={breadcrumbs}
      renderSearch={({ onClose, searchTerm }) => (
        <SearchResults searchTerm={searchTerm} onItemSelected={onClose} />
      )}
      header={<DevMenu />}
    >
      {children}
    </RocketLayout>
  )
}

export default Frame
