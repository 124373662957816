import Typeahead from '@/gf/components/next/Typeahead'
import useGoogleMapsScriptLoader from '@/gf/hooks/useGoogleMapsScriptLoader'
import GoogleMaps, { GoogleBusinessDetails } from '@/gf/modules/GoogleMaps'

const GoogleBusinessTypeahead = ({
  value,
  onChange,
  onDetailsFound,
}: {
  value: string
  onChange: (newValue: string) => void
  onDetailsFound: (businessDetails: GoogleBusinessDetails) => void
}) => {
  const { isLoaded } = useGoogleMapsScriptLoader()

  const searchPlaces = async (searchTerm: string) => {
    if (!searchTerm || !isLoaded) {
      return []
    }

    const predictions = await GoogleMaps.autocompletePlaces(searchTerm, {
      country: ['US'],
      types: ['establishment'],
    })

    return predictions.map((p) => ({
      name: p.structured_formatting.main_text,
      address: p.structured_formatting.secondary_text,
      placeId: p.place_id,
      id: p.place_id,
    }))
  }

  const onPlaceSelected = async (placeId: string) => {
    const businessDetails = await GoogleMaps.getPlaceBusinessDetails(placeId)

    onDetailsFound(businessDetails)
  }

  return (
    <Typeahead
      value={value}
      onChange={onChange}
      onFetchOptions={searchPlaces}
      placeholder=""
      className="w-full"
      renderOption={(o) => `${o.name} - ${o.address}`}
      transformSelection={(o) => o.name}
      afterSelection={(o) => onPlaceSelected(o.placeId)}
    />
  )
}

export default GoogleBusinessTypeahead
