import PriceV2 from '@/gf/components/inputs/PriceV2'
import ThreeDotsDropdown from '@/gf/components/ThreeDotsDropdown'
import MoneyM from '@/gf/modules/Money'
import { cn } from '@/gf/modules/utils'
import { ArrowDownIcon, TrashIcon } from '@heroicons/react/outline'
import { ButtonHTMLAttributes, TdHTMLAttributes, ThHTMLAttributes } from 'react'
import useForm, { EditableShippingRate } from './useForm'

const zeroPrice = MoneyM.fromInt(0, 'USD')
const oneCent = MoneyM.fromInt(1, 'USD')

const subtotalFrom = (index: number, shippingRates: EditableShippingRate[]) => {
  if (index === 0) return MoneyM.format(zeroPrice)

  const prevShippingRate = shippingRates[index - 1]

  return prevShippingRate.subtotal
    ? MoneyM.format(MoneyM.add(prevShippingRate.subtotal, oneCent))
    : '-'
}

const Th = ({ className, ...props }: ThHTMLAttributes<HTMLTableCellElement>) => (
  <th className={cn('font-medium text-sm px-2 py-3 whitespace-nowrap', className)} {...props} />
)

const Td = ({ className, ...props }: TdHTMLAttributes<HTMLTableCellElement>) => (
  <td className={cn('align-middle whitespace-nowrap px-2 py-3', className)} {...props} />
)

const MenuButton = (props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'className'>) => (
  <button
    type="button"
    className="flex gap-2 items-center w-full px-4 py-2 hover:bg-gray-50"
    {...props}
  />
)

const Table = ({
  shippingRates,
  editing,
  updateSubtotal,
  updateShipping,
  insertBelow,
  deleteRow,
}: ReturnType<typeof useForm>) => (
  <table className="divide-y divide-gray-200">
    <thead>
      <tr>
        <Th colSpan={3} className="text-center">
          Order Subtotal
        </Th>

        <Th className="pl-4 sm:pl-12 text-left">Shipping Cost</Th>
        {editing && <Th>Options</Th>}
      </tr>
    </thead>

    <tbody className="divide-y divide-gray-200">
      {shippingRates?.map((shippingRate, index) => (
        <tr key={shippingRate.id}>
          <Td className="text-right">{subtotalFrom(index, shippingRates)}</Td>

          {index < shippingRates.length - 1 ? (
            <>
              <Td className="text-gray-500 text-center">to</Td>

              <Td className="text-right">
                {editing ? (
                  <PriceV2
                    price={shippingRate.subtotal}
                    onChange={(subtotal) => updateSubtotal(index, subtotal || null)}
                    className="min-w-28 max-w-36"
                  />
                ) : (
                  shippingRate.subtotal && MoneyM.format(shippingRate.subtotal)
                )}
              </Td>
            </>
          ) : (
            <Td colSpan={2} className="text-gray-500 text-left">
              and above
            </Td>
          )}

          <Td className="pl-4 sm:pl-12 text-right">
            {editing ? (
              <PriceV2
                price={shippingRate.shipping}
                onChange={(shipping) => updateShipping(index, shipping || null)}
                className="min-w-28 max-w-36"
              />
            ) : (
              shippingRate.shipping && MoneyM.format(shippingRate.shipping)
            )}
          </Td>

          {editing && (
            <Td className="text-center">
              <ThreeDotsDropdown>
                <div>
                  {(index < shippingRates.length - 1 || shippingRates.length === 1) && (
                    <MenuButton onClick={() => insertBelow(index)}>
                      <ArrowDownIcon className="w-5 h-5" /> Insert Below
                    </MenuButton>
                  )}

                  {shippingRates.length > 1 && (
                    <MenuButton onClick={() => deleteRow(index)}>
                      <TrashIcon className="w-5 h-5" /> Delete
                    </MenuButton>
                  )}
                </div>
              </ThreeDotsDropdown>
            </Td>
          )}
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
