import {
  ConversationInput,
  MessageInput,
  Organization as OrganizationT,
  ReadReceipt as ReadReceiptT,
  ReadReceiptsForConversationQueryResult,
  ReadReceiptsForConversationQueryVariables,
  User as UserT,
} from '@/buyers/_gen/gql'
import Button from '@/gf/components/ButtonOld'
import Layout from '@/gf/components/Layout'
import Link from '@/gf/components/Link'
import LinkButton from '@/gf/components/LinkButtonOld'
import MessageAttachment from '@/gf/components/MessageAttachment'
import NotificationDot from '@/gf/components/NotificationDot'
import Spinner from '@/gf/components/Spinner'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useUppy from '@/gf/hooks/useUppy'
import useWindowWidth from '@/gf/hooks/useWindowWidth'
import ConversationM from '@/gf/modules/Conversation'
import Time from '@/gf/modules/Time'
import { Pagination } from '@/types'
import { ApolloError, QueryHookOptions } from '@apollo/client'
import { ClockIcon } from '@heroicons/react/outline'
import {
  ChevronLeftIcon,
  InformationCircleIcon,
  PaperAirplaneIcon,
  PhotographIcon,
  PlayIcon,
  PlusIcon,
  XIcon,
} from '@heroicons/react/solid'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useRef, useState } from 'react'
import useSession from '../hooks/useSession'
import ConversationRowIcon from './Inbox/ConversationRowIcon'
import InboxMessages from './Inbox/Messages'
import {
  Conversation,
  ConversationRowContent,
  DetailsForConversationProps,
  File,
  NewConversation,
  UseMessagesForConversationHook,
} from './Inbox/types'

type ReadReceipt = Pick<ReadReceiptT, 'id' | 'updatedAt'> & {
  user: Pick<UserT, 'id' | 'role' | 'name'> & {
    organization: Pick<OrganizationT, 'id'> | null
  }
}

export type useReadReceiptsForConversationT = (
  params: Pick<QueryHookOptions, 'onCompleted' | 'client' | 'pollInterval' | 'skip'> & {
    variables: ReadReceiptsForConversationQueryVariables
  }
) => Pick<ReadReceiptsForConversationQueryResult, 'loading' | 'error' | 'refetch'> & {
  data: { readReceiptsForConversation: ReadReceipt[] } | undefined
}

const attachmentAllowedFileTypes = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.mov', '.mp4']

const messageFailureMessage = 'Error sending message, please contact support if the error persists.'

const getContentEditableText = (contentEditableDiv: HTMLDivElement) => {
  // Linebreaks aren't processed correctly with .innerText
  let messageText = ''
  contentEditableDiv.childNodes.forEach((childNode, key) => {
    messageText +=
      (key !== 0 && (childNode.nodeName === 'DIV' || childNode.nodeName === 'P') ? '\n' : '') +
      (childNode.textContent || '')
  })
  return messageText
}

const ConversationRow = ({
  selected,
  conversation,
  onClick,
  conversationRowContent,
}: {
  selected: boolean
  conversation: Conversation | NewConversation
  onClick: () => void
  conversationRowContent: (conversation: Conversation | NewConversation) => ConversationRowContent
}) => {
  const { name, details } = conversationRowContent(conversation)
  const detailsList = !details ? undefined : Array.isArray(details) ? details : [details]
  return (
    <button
      className={classNames(
        'flex p-4 items-center justify-between rounded-md hover:bg-gray-100 text-sm text-left text-gray-700 outline-none',
        {
          'bg-gray-100': selected,
        }
      )}
      type="button"
      onClick={onClick}
    >
      <div className="flex flex-col overflow-hidden">
        {typeof name === 'string' || Array.isArray(name) ? (
          (typeof name === 'string' ? [name] : name).map((n) => (
            <div key={`${conversation.id}-${n}`} className="font-medium text-sm text-gray-800">
              {n}
            </div>
          ))
        ) : (
          <div className="inline-flex items-center space-x-1 font-medium text-sm text-gray-800">
            <ConversationRowIcon type={name.type} />
            <span>{name.name}</span>
          </div>
        )}
        {detailsList && (
          <div className="mt-1 flex flex-col space-y-1">
            {detailsList.map((d) => (
              <div
                key={`${conversation.id}-${d.name}`}
                className="inline-flex items-center space-x-1 text-gray-500 text-sm"
              >
                <ConversationRowIcon type={d.type} />
                <span>{d.name}</span>
              </div>
            ))}
          </div>
        )}
        {conversation.lastMessage && (
          <div className="mt-1 inline-flex justify-start items-center flex-nowrap text-xs text-gray-500 overflow-hidden">
            <span className="whitespace-nowrap text-ellipsis overflow-hidden">
              {conversation.lastMessage.text ||
                `[attachment: ${decodeURI(
                  conversation.lastMessage.attachmentUrls[0].split('/').reverse()[0]
                )}]`}
            </span>
            <span className="mx-1 inline-flex rounded-full w-0.5 h-0.5 min-w-0.5 bg-gray-500" />
            <span>{Time.formatDiffShort(conversation.lastMessage.insertedAt)}</span>
          </div>
        )}
      </div>
      {conversation.unreadMessages && <NotificationDot className="ml-3" />}
    </button>
  )
}

const Inbox = ({
  conversations,
  selectedConversationId,
  onSelectedConversationIdChange,
  useMessagesForConversation,
  useReadReceiptsForConversation,
  refetchConversations,
  page,
  setPage,
  pagination,
  newConversations = [],
  displayCustomerContactInfo = true,
  DetailsForConversation,
  conversationRowContent,
  onCreateMessage,
  onCreateConversation,
  className,
}: {
  conversations?: Conversation[]
  selectedConversationId: string | undefined
  onSelectedConversationIdChange: (conversationId: string | undefined) => void
  useMessagesForConversation: UseMessagesForConversationHook
  useReadReceiptsForConversation: useReadReceiptsForConversationT
  refetchConversations: () => Promise<unknown>
  page: number
  setPage: (page: number) => void
  pagination: Pagination
  newConversations?: NewConversation[]
  displayCustomerContactInfo?: boolean | ((conversation: Conversation | NewConversation) => boolean)
  conversationRowContent: (conversation: Conversation | NewConversation) => ConversationRowContent
  DetailsForConversation: (props: DetailsForConversationProps) => JSX.Element
  onCreateMessage: ({ messageInput }: { messageInput: MessageInput }) => Promise<unknown>
  onCreateConversation: ({
    conversationInput,
    messageText,
    attachmentUrls,
  }: {
    conversationInput: ConversationInput
    messageText: string
    attachmentUrls?: string[]
  }) => Promise<string | undefined>
  className?: string
}) => {
  const { user } = useSession()
  const [_msgs, msgsMgr] = useMsgs()
  const width = useWindowWidth()
  // Note: these sizes do not correlate to Tailwind's responsive sizes
  const size = width < 640 ? 'small' : width < 1024 ? 'medium' : 'large'

  const selectedConversation =
    conversations &&
    [...conversations, ...newConversations].find(
      (conversation) => conversation.id === selectedConversationId
    )

  const displayCustomerInfo: boolean =
    typeof displayCustomerContactInfo === 'boolean'
      ? displayCustomerContactInfo
      : !!selectedConversation && displayCustomerContactInfo(selectedConversation)

  const [spinnerLive, spinnerToggler] = useToggle()
  const [showDetails, setShowDetails] = useState(false)
  const [newMessageFiles, setNewMessageFiles] = useState<File[]>([])
  const [newMessageFocused, newMessageFocusedToggler] = useToggle()
  const [newMessageDropFocused, newMessageDropFocusedToggler] = useToggle()
  const attachmentInputRef = useRef<HTMLInputElement>(null)
  const messageRef = useRef<HTMLDivElement>(null)
  // refs don't cause re-renders, so we need use this state to track the messageRef text
  const [newMessageText, setNewMessageText] = useState('')

  const uppy = useUppy({
    onFilesAdded: (files) => {
      files.forEach((file) => {
        const reader = new FileReader()
        reader.onloadend = (e) => {
          if (e.target?.result && typeof e.target.result === 'string') {
            const fileWithData: File = {
              id: file.id,
              name: file.name,
              extension: file.extension,
              type: file.type,
              data: e.target.result,
            }

            setNewMessageFiles((prevFiles) => [...prevFiles, fileWithData])
          }
        }
        reader.readAsDataURL(file.data)
      })
    },
    allowedFileTypes: attachmentAllowedFileTypes,
    autoProceed: false,
    restrictions: { maxFileSize: 25000000 },
  })

  const hideConversations = size === 'small' && selectedConversationId
  const hideMessages =
    (size === 'small' && !selectedConversationId) || (size !== 'large' && showDetails)
  const hideDetails =
    (size === 'small' && !selectedConversationId) || (size !== 'large' && !showDetails)

  const cannotSendMessage =
    spinnerLive || (newMessageText.trim() === '' && newMessageFiles.length === 0)

  const resetMessage = () => {
    setNewMessageFiles([])
    setNewMessageText('')
    // Clear editable message div text
    if (messageRef.current) {
      while (messageRef.current.firstChild) {
        messageRef.current.firstChild.remove()
      }
    }
  }

  const sendMessage = () => {
    if (!selectedConversationId || !selectedConversation) {
      msgsMgr.add('Select a conversation before sending a message', 'negative')
      return
    }
    if (cannotSendMessage) return
    spinnerToggler.on()
    uppy
      .upload()
      .then((files) => {
        const newMessageAttachmentUrls = files.successful.map((file) => file.uploadURL)
        if (!('newConversation' in selectedConversation)) {
          // Existing Conversation
          onCreateMessage({
            messageInput: {
              conversationId: selectedConversation.id,
              text: newMessageText,
              attachmentUrls: newMessageAttachmentUrls,
            },
          })
            .then(() => {
              resetMessage()
              spinnerToggler.off()
              // Go to the first page, because this conversation will now be on the first page
              if (page !== 1) setPage(1)
            })
            .catch((err: ApolloError) => {
              msgsMgr.add(
                !err.message || err.message.includes('DOCTYPE')
                  ? messageFailureMessage
                  : err.message,
                'negative'
              )
              spinnerToggler.off()
            })
        } else {
          // New Conversation
          spinnerToggler.on()
          onCreateConversation({
            conversationInput: {
              requestForQuoteId:
                selectedConversation.source.type === 'request'
                  ? selectedConversation.source.id
                  : null,
              storeOrderId:
                selectedConversation.source.type === 'store_order'
                  ? selectedConversation.source.id
                  : null,
              storeId: selectedConversation.storeId,
              admin: selectedConversation.admin,
            },
            messageText: newMessageText,
            attachmentUrls: newMessageAttachmentUrls,
          })
            .then((conversationId) => {
              // Select the new conversation
              onSelectedConversationIdChange(conversationId)
              resetMessage()
            })
            .catch((err: ApolloError) => {
              msgsMgr.add(
                !err.message || err.message.includes('DOCTYPE')
                  ? messageFailureMessage
                  : err.message,
                'negative'
              )
            })
            .finally(spinnerToggler.off)
        }
      })
      .catch(() => {
        msgsMgr.add('Error uploading files', 'negative')
        spinnerToggler.off()
      })
  }

  const addFiles = (files: (globalThis.File | null)[]) => {
    files.forEach((file) => {
      if (file) {
        try {
          uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          })
        } catch (error) {
          const err = error as { isRestriction: boolean }
          if (err.isRestriction) {
            // handle restrictions
            msgsMgr.add(`${err}`, 'negative')
          } else {
            // handle other errors
            msgsMgr.add('Error attaching file', 'negative')
          }
        }
      }
    })
  }

  return (
    <Layout.FullPageLayout className={classNames('-mb-4 mt-2', className)}>
      <div className="h-full flex flex-col bg-white shadow-sm border rounded-md">
        <div
          className={classNames(
            size === 'small' ? 'grid-cols-1' : size === 'medium' ? 'grid-cols-3' : 'grid-cols-4',
            'h-full grid divide-x'
          )}
        >
          {/* Conversations */}
          <div
            className={classNames(
              hideConversations ? 'hidden' : 'flex',
              'flex-col overflow-y-scroll'
            )}
          >
            <div
              className={classNames(
                size === 'small' && 'divide-y',
                'flex flex-col justify-stretch grow p-2'
              )}
            >
              {page !== 1 && (
                <div className="my-2 text-center text-sm">
                  <LinkButton key="load-previous-page" onClick={() => setPage(page - 1)}>
                    {`Newer Messages (${ConversationM.PAGE_SIZE * (page - 1)})`}
                  </LinkButton>
                </div>
              )}

              {!conversations && (
                <div className="p-4">
                  <Spinner />
                </div>
              )}

              {conversations &&
                [...conversations, ...newConversations].map((conversation) => (
                  <div
                    key={`conversation-${conversation.id}`}
                    className="flex flex-col justify-stretch"
                  >
                    <ConversationRow
                      conversation={conversation}
                      selected={conversation.id === selectedConversationId}
                      onClick={() => onSelectedConversationIdChange(conversation.id)}
                      conversationRowContent={conversationRowContent}
                    />
                  </div>
                ))}

              {page < pagination.totalPages && (
                <div className="my-2 text-center text-sm">
                  <LinkButton key="load-next-page" onClick={() => setPage(page + 1)}>
                    Older Messages
                  </LinkButton>
                </div>
              )}
            </div>

            {conversations &&
              ![...conversations, ...newConversations].find(
                (conversation) => !conversation.admin
              ) && (
                <div className="m-4 flex-col grow space-y-4 text-left text-sm text-gray-500">
                  <p>When you receive a message from a Vendor, it will appear here.</p>
                  <p>You can also message Vendors from here once they send you a quote.</p>
                </div>
              )}
          </div>

          {/* Messages */}
          <div className={classNames(hideMessages ? 'hidden' : 'block', 'relative col-span-2')}>
            <div
              className={classNames(hideMessages ? 'hidden' : 'flex', 'absolute inset-0 flex-col')}
            >
              <div
                className={classNames(
                  hideMessages || size === 'large' ? 'hidden' : 'flex',
                  'p-2 border-b shadow-sm flex flex-row items-center justify-between'
                )}
              >
                {size === 'small' && (
                  <button
                    type="button"
                    className="p-2 rounded-full hover:bg-gray-100"
                    onClick={() => onSelectedConversationIdChange(undefined)}
                  >
                    <ChevronLeftIcon className="w-5 h-5 text-gray-700" />
                  </button>
                )}
                <span className={size !== 'small' ? 'ml-2' : undefined}>
                  {selectedConversation?.name}
                </span>
                <button
                  type="button"
                  className="p-2 rounded-full hover:bg-gray-100"
                  disabled={!selectedConversationId}
                  onClick={() => setShowDetails(true)}
                >
                  <InformationCircleIcon className="w-5 h-5 text-gray-700" />
                </button>
              </div>
              <div className="flex grow overflow-y-hidden">
                {!selectedConversationId || !selectedConversation ? (
                  <div className="flex grow text-sm text-gray-500 justify-center items-center">
                    Select a conversation on the left
                  </div>
                ) : 'newConversation' in selectedConversation ? (
                  <div className="flex grow text-sm text-gray-500 justify-center items-center">
                    Send a message
                  </div>
                ) : (
                  <InboxMessages
                    conversation={selectedConversation}
                    useMessagesForConversation={useMessagesForConversation}
                    useReadReceiptsForConversation={useReadReceiptsForConversation}
                    refetchConversations={refetchConversations}
                    userId={user.id}
                    displayCustomerContactInfo={displayCustomerInfo}
                  />
                )}
              </div>
              {selectedConversation?.scheduledMessage && (
                <div className="px-4 pt-2">
                  <p className="px-3 py-2 w-full inline-flex items-center gap-x-3 text-gray-700 bg-gray-100 text-xs rounded-lg">
                    <ClockIcon className="-m-0.5 inline-block h-5 w-5 text-gray-600" />
                    <span className="inline-flex items-center gap-x-2">
                      Your message will be sent{' '}
                      {Time.formatRelativeDay(
                        selectedConversation.scheduledMessage.scheduledFor,
                        DateTime.DATE_MED
                      )}
                      , {selectedConversation.scheduledMessage.scheduledFor.toFormat('t')}.{' '}
                      <Link.T
                        className="pb-px"
                        to={selectedConversation.scheduledMessage.viewMorePath}
                      >
                        See all scheduled messages
                      </Link.T>
                    </span>
                  </p>
                </div>
              )}
              <form
                className="px-4 py-2 flex flex-row items-end gap-x-2"
                onSubmit={(e) => {
                  e.preventDefault()
                  sendMessage()
                }}
              >
                <div>
                  <input
                    ref={attachmentInputRef}
                    type="file"
                    className="hidden"
                    accept={attachmentAllowedFileTypes.join(',')}
                    multiple
                    onChange={(event) => addFiles(Array.from(event.target.files || []))}
                    // Keep the input value cleared with an empty string
                    value=""
                  />
                  <button
                    id="message-attachments-button"
                    type="button"
                    className="rounded-full p-2 bg-gray-200 hover:bg-gray-300"
                    onClick={() => attachmentInputRef.current?.click()}
                  >
                    <PhotographIcon className="w-5 h-5 text-gray-700" />
                  </button>
                </div>
                <div
                  className={classNames(
                    'flex flex-col grow rounded-md border',
                    newMessageDropFocused
                      ? 'ring-1 ring-orange-500 border-orange-500'
                      : newMessageFocused
                      ? 'ring-1 ring-indigo-500 border-indigo-500'
                      : 'border-gray-300'
                  )}
                  onDragLeave={(e) => {
                    e.preventDefault()
                    newMessageDropFocusedToggler.off()
                  }}
                  onDragOver={(e) => {
                    e.preventDefault()
                    newMessageDropFocusedToggler.on()
                  }}
                  onDragEnter={(e) => e.preventDefault()}
                  onDrop={(event) => {
                    event.preventDefault()
                    newMessageDropFocusedToggler.off()
                    const files = event.dataTransfer.items
                      ? Array.from(event.dataTransfer.items)
                          .filter((item) => item.kind === 'file')
                          .map((item) => item.getAsFile())
                      : Array.from(event.dataTransfer.files)
                    addFiles(files)
                  }}
                >
                  {newMessageFiles.length !== 0 && (
                    <div className="mx-2 mt-3 mb-2 flex flex-row items-center rounded-md gap-x-4 gap-y-3 flex-wrap">
                      {newMessageFiles.map((file) => (
                        <div key={`new-attachment-${file.name}`} className="relative">
                          {file.extension === 'pdf' ? (
                            <MessageAttachment className="max-w-24 sm:max-w-32" name={file.name} />
                          ) : ['mov', 'mp4'].includes(file.extension) ? (
                            <PlayIcon className="w-14 h-14" />
                          ) : (
                            <img
                              className="w-14 h-14 min-w-14 min-h-14 object-cover rounded-lg"
                              src={file.data}
                              alt={file.name}
                            />
                          )}
                          <button
                            type="button"
                            className="p-1 absolute -top-2 -right-2 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500 border border-gray-300 shadow"
                            title="Delete"
                            onClick={() => {
                              uppy.removeFile(file.id)
                              setNewMessageFiles((prevAttachments) => {
                                const attachments = [...prevAttachments]
                                attachments.splice(
                                  attachments.findIndex((a) => a.id === file.id),
                                  1
                                )
                                return attachments
                              })
                            }}
                          >
                            <XIcon className="h-4 w-4 text-gray-700" />
                          </button>
                        </div>
                      ))}
                      <button
                        key="add-attachment"
                        type="button"
                        className="w-14 h-14 min-w-14 min-h-14 bg-gray-200 hover:bg-gray-300 rounded-lg inline-flex justify-center items-center"
                        onClick={() => attachmentInputRef.current?.click()}
                      >
                        <PlusIcon className="w-5 h-5 text-gray-700" />
                      </button>
                    </div>
                  )}
                  <div className="relative flex grow">
                    <div
                      ref={messageRef}
                      className="p-2 w-full max-h-40 outline-none rounded-md text-sm whitespace-pre-wrap bg-transparent z-10 overflow-y-scroll"
                      contentEditable
                      role="textbox"
                      spellCheck
                      tabIndex={0}
                      aria-label="Message"
                      onKeyDown={(e) => {
                        if (spinnerLive) {
                          // Lock text while sending message
                          e.preventDefault()
                        } else if (!e.shiftKey && e.key === 'Enter') {
                          // Send message for enter key (shift + enter adds a newline)
                          sendMessage()
                          // Stop Enter event from contributing to the input text box
                          e.preventDefault()
                        }
                      }}
                      onInput={(e) =>
                        setNewMessageText(getContentEditableText(e.target as HTMLDivElement))
                      }
                      onPaste={(e) => {
                        e.preventDefault()
                        if (e.clipboardData.files.length > 0) {
                          // Upload file(s)
                          addFiles(Array.from(e.clipboardData.files))
                        }
                        // Transform data to make sure we just get plaintext
                        // Code from MDN: https://developer.mozilla.org/en-US/docs/Web/API/Element/paste_event
                        const paste = e.clipboardData.getData('text/plain')
                        const selection = window.getSelection()
                        if (!selection?.rangeCount) return
                        selection.deleteFromDocument()
                        selection.getRangeAt(0).insertNode(document.createTextNode(paste))
                        // Update message text
                        if (messageRef.current)
                          setNewMessageText(getContentEditableText(messageRef.current))
                      }}
                      onFocus={newMessageFocusedToggler.on}
                      onBlur={newMessageFocusedToggler.off}
                    />
                    {newMessageText === '' && (
                      <div
                        className="absolute pl-2 inset-0 inline-flex items-center align-middle text-gray-400 border border-transparent"
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        Aa
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  className="px-2"
                  type="submit"
                  disabled={cannotSendMessage}
                  performing={spinnerLive}
                >
                  <PaperAirplaneIcon className="w-5 h-5 rotate-90" />
                </Button>
              </form>
            </div>
          </div>

          {/* Details */}
          <div
            className={classNames(
              hideDetails ? 'hidden' : 'block',
              size === 'large' ? 'col-span-1' : 'col-span-2',
              'relative'
            )}
          >
            <div
              className={classNames(
                hideDetails ? 'hidden' : 'flex',
                'absolute inset-0 flex-col justify-stretch'
              )}
            >
              <div
                className={classNames(
                  hideDetails || size === 'large' ? 'hidden' : 'flex',
                  'p-2 border-b shadow-sm flex flex-row items-center justify-between'
                )}
              >
                <span className="ml-2">Details</span>
                <button
                  type="button"
                  className="p-2 rounded-full hover:bg-gray-100"
                  onClick={() => setShowDetails(false)}
                >
                  <XIcon className="w-5 h-5 text-gray-700" />
                </button>
              </div>
              <div className="p-4 space-y-6 flex flex-col grow overflow-y-scroll">
                {selectedConversation && (
                  <DetailsForConversation conversation={selectedConversation} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout.FullPageLayout>
  )
}

export default Inbox
