import {
  DeliveryMethod,
  PaymentMethod,
  RequestForQuoteStep,
  StoreOrderState,
} from '@/dealers/_gen/gql'
import Urls from '@/dealers/modules/Urls'
import A from '@/gf/components/A'
import Box from '@/gf/components/Box'
import ImageGallery from '@/gf/components/ImageGallery'
import Link from '@/gf/components/Link'
import MoneyM from '@/gf/modules/Money'
import StoreOrderM from '@/gf/modules/StoreOrder'
import {
  CheckCircleIcon,
  PencilIcon,
  ReceiptRefundIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'
import { Fragment, useMemo } from 'react'
import Address from '../Address'
import { useRfqContext } from '../context'
import MachineInformation from '../MachineInformation'
import SidebarLayout from '../SidebarLayout'
import Summary from '../Summary'
import { calcRefundableAmount } from './Refund'
import LineItems from './ViewQuote/LineItems'

const zeroPrice = MoneyM.fromInt(0, 'USD')

const PartInfo = ({ label, content }) => (
  <div className="flex gap-x-8">
    <div className="w-full max-w-[9.25rem] leading-6">{label}</div>
    <div className="leading-6">{content}</div>
  </div>
)

const Section = ({ children, className = '' }) => (
  <div className={classNames('py-6 first:pt-0 last:pb-0', className)}>{children}</div>
)

const ViewQuote = () => {
  const { rfq } = useRfqContext()
  const showQuoteActions =
    [RequestForQuoteStep.Quoted, RequestForQuoteStep.PoReceived].includes(rfq.step) ||
    rfq.quote?.state === StoreOrderState.QuoteDenied

  const showRefundAction = rfq.quote?.refundable && MoneyM.isPos(calcRefundableAmount(rfq.quote))

  const [approvedLineItems, rejectedLineItems] = useMemo(
    () => [
      rfq.quote?.lineItems.filter((li) => !li.rejectedAt) ?? [],
      rfq.quote?.lineItems.filter((li) => !!li.rejectedAt) ?? [],
    ],
    [rfq.quote]
  )

  if (!rfq.quote) return null

  const { quote } = rfq

  return (
    <SidebarLayout className="space-y-4 flex-grow">
      <Box className="shadow-base flex-grow p-6 flex flex-col gap-y-6">
        {(showQuoteActions || showRefundAction) && (
          <div className="w-full flex flex-row flex-wrap justify-between items-center gap-x-2">
            {(rfq.step === RequestForQuoteStep.Quoted ||
              rfq.quote?.state === StoreOrderState.QuoteDenied) && (
              <Link.S
                className="flex justify-center items-center gap-x-1 text-gray-900"
                to={`${Urls.requestForQuote(rfq.id)}?edit=1`}
              >
                <PencilIcon className="w-5 h-5 flex shrink-0" />
                Update Quote
              </Link.S>
            )}

            {(rfq.step === RequestForQuoteStep.Quoted ||
              rfq.quote?.state === StoreOrderState.QuoteDenied) && (
              <Link.S
                className="flex justify-center items-center gap-x-1 text-gray-900"
                to="close-quote"
              >
                <XIcon className="w-5 h-5 flex shrink-0" />
                Close Quote
              </Link.S>
            )}

            {showRefundAction && (
              <Link.S
                className="flex justify-center items-center gap-x-1 text-gray-900"
                to={`/rfqs/${rfq.id}/refund`}
                key="refund"
              >
                <ReceiptRefundIcon className="w-5 h-5 flex shrink-0" />
                Refund
              </Link.S>
            )}
          </div>
        )}

        <div className="divide-y-1">
          <div className="flex flex-col gap-y-6">
            <LineItems
              lineItems={approvedLineItems}
              title={
                rejectedLineItems.length > 0 ? (
                  <>
                    <CheckCircleIcon className="w-5 h-5 text-green-500" /> Approved Parts
                  </>
                ) : (
                  'Parts'
                )
              }
              deliveryMethod={quote.deliveryMethod}
            />
          </div>

          {rejectedLineItems.length === 0 && (
            <Section>
              <Summary
                total={quote.totals.total}
                subtotal={MoneyM.add(quote.totals.subtotal, quote.totals.discount)}
                discountPercent={quote.discounts.reduce(
                  (sum: number, discount: { percent: number }) => sum + discount.percent,
                  0
                )}
                discount={quote.totals.discount}
                shippingCost={quote.shippingAmount}
                taxCost={
                  rfq.requestDetails || !MoneyM.isZero(quote.salesTax) ? quote.salesTax : null
                }
                customerFee={quote.totals.customerFee}
                refunded={quote.refunds.map((r) => r.amount).reduce(MoneyM.add, zeroPrice)}
                showShippingCost={quote.deliveryMethod === DeliveryMethod.Shipping}
                paidWithPaymentMethod={
                  StoreOrderM.isQuoteApproved(quote)
                    ? quote.paymentMethod === PaymentMethod.Direct
                      ? { accountNumber: quote.payingAccountNumber ?? '' }
                      : 'gearflow_payments'
                    : undefined
                }
                className="max-w-[21rem]"
              />
            </Section>
          )}

          {rfq.images.length > 0 && (
            <Section>
              <ImageGallery images={rfq.images.map((i) => i.url)} />
            </Section>
          )}

          {quote.deliveryMethod !== DeliveryMethod.Pickup && (
            <>
              {quote.shippingAddress && (
                <Section>
                  <Address address={quote.shippingAddress} textSize="md" title="Shipping Address" />
                </Section>
              )}

              {quote.shipments.length > 0 && (
                <Section>
                  <h4 className="text-lg font-medium">Shipping Details</h4>
                  {quote.shipments.map((shipment) => (
                    <Fragment key={shipment.id}>
                      <div
                        className={classNames(
                          'mt-2 ',
                          quote.shipments.length > 1 && 'bg-gray-50 p-4 rounded-lg'
                        )}
                      >
                        <PartInfo label="Carrier:" content={shipment.shippingCarrier} />
                        <PartInfo
                          label="Tracking Number:"
                          content={
                            shipment.trackingLink ? (
                              <A.T href={shipment.trackingLink} target="_blank">
                                {shipment.trackingNumber}
                              </A.T>
                            ) : (
                              shipment.trackingNumber
                            )
                          }
                        />
                        <PartInfo
                          label="Date Shipped:"
                          content={shipment.shippedAt.toFormat('D')}
                        />
                      </div>
                    </Fragment>
                  ))}
                </Section>
              )}
            </>
          )}

          {quote.pickupAddress && (
            <Section>
              <Address address={quote.pickupAddress} textSize="md" title="Will Call Location" />
            </Section>
          )}

          <Section>
            <MachineInformation reqMachine={rfq.machines[0] ?? null} textSize="base" />

            {rfq.partsRequest && (
              <p className="leading-6 prose whitespace-pre-wrap mt-6">
                Comments: {rfq.partsRequest}
              </p>
            )}
          </Section>

          {quote.timingDetails && quote.timingDetails !== '' && (
            <Section>
              <h4 className="font-medium text-lg text-gray-900">Expected lead time</h4>
              <p className="leading-6 prose whitespace-pre-wrap">{quote.timingDetails}</p>
            </Section>
          )}
        </div>
      </Box>

      {rejectedLineItems.length > 0 && (
        <Box className="shadow-base flex-grow p-6 flex flex-col gap-y-6 bg-gray-50">
          <LineItems
            lineItems={rejectedLineItems}
            title={
              <>
                <XCircleIcon className="w-5 h-5 text-red-500" /> Rejected Parts
              </>
            }
            subtitle="The part(s) below have been removed from your updated quote."
            deliveryMethod={quote.deliveryMethod}
            className="border-b border-gray-200"
          />

          <div>
            <h4 className="text-lg font-medium">Rejected Reason</h4>
            <p className="text-base mt-2">
              {quote.timeline.denialReason}
              {quote.timeline.denialAdditionalComments && (
                <>
                  <br />“{quote.timeline.denialAdditionalComments}”
                </>
              )}
            </p>
          </div>
        </Box>
      )}
    </SidebarLayout>
  )
}

export default ViewQuote
