import { useCallback } from 'react'
import Tag from '@/gf/components/Reports/Tag'
import useChartPointSymbol from '@/gf/hooks/useChartPointSymbol'
import { PointSymbolProps, PointTooltip, ResponsiveLine } from '@nivo/line'
import map from 'lodash/map'
import { DateTime } from 'luxon'
import pluralize from 'pluralize'
import { ChartTabs } from '../ChartTabs'
import { formatConversionRate, rfqsToConversionRate } from '../Performance'
import { filterRfqsReceivedBetween } from '../Service'
import { useContext } from './context'
import { Rfq } from './useQuery'
import ChartM from '@/gf/modules/Chart'

const gray700 = 'rgb(55, 65, 81)'

const Tooltip: PointTooltip = ({ point }) => {
  const data = point.data as typeof point.data & { rfqCount: number }
  const { chartTab } = useContext()
  const date = DateTime.fromJSDate(point.data.x as Date)
  const rfqCountOrConversionRate = point.data.y as number

  return (
    <div
      key={point.id}
      className="text-sm px-3 py-2 flex flex-col gap-y-1 bg-white border-gray-300 border rounded shadow-md"
    >
      <span>
        {chartTab === 'trend'
          ? `Week of ${date.toLocaleString(DateTime.DATE_MED)}`
          : date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
      </span>

      {data.rfqCount === 0 && chartTab === 'trend' ? (
        <span className="text-gray-500 italic">no data</span>
      ) : (
        <span className="font-medium">
          {chartTab === 'trend' && (
            <>{formatConversionRate(rfqCountOrConversionRate)} Conversion Rate</>
          )}
          {chartTab === 'activity' && (
            <>
              {rfqCountOrConversionRate.toLocaleString()} Converted{' '}
              {pluralize('Quote', rfqCountOrConversionRate)}
            </>
          )}
        </span>
      )}
    </div>
  )
}

const Chart = () => {
  const {
    rfqs: unfilteredRfqs,
    filteredRfqs,
    fromDate,
    toDate,
    date,
    orgId,
    creatorId,
    orgById,
    creatorById,
    chartTab,
    ranges,
    update,
  } = useContext()

  const isEmptyCallback = useCallback((props: PointSymbolProps) => props.datum.rfqCount === 0, [])
  const pointSymbol = useChartPointSymbol(
    date || undefined,
    chartTab === 'trend' ? isEmptyCallback : undefined
  )
  const days = toDate.diff(fromDate).as('days')
  const convertedUnfilteredRfqs = unfilteredRfqs?.filter((r) => r.converted)
  const convertedFilteredRfqs = filteredRfqs?.filter((r) => r.converted)

  const [unfilteredData, filteredData] = [convertedUnfilteredRfqs, convertedFilteredRfqs].map(
    (convertedRfqs) => {
      if (!convertedRfqs) return []

      // { "2024-02-24": [ rfq, ... ], ... ]
      const convertedRfqsByIsoDate = ranges.reduce<Record<string, Rfq[]>>((acc, [start, end]) => {
        const subRfqs = filterRfqsReceivedBetween(convertedRfqs, [start, end])
        return { ...acc, [start.toISODate()]: subRfqs }
      }, {})

      // [ { x: "2024-02-24", y: 14 }, ... ]
      return map(convertedRfqsByIsoDate, (subConvertedRfqs, isoDate) => ({
        x: isoDate,
        y:
          (chartTab === 'trend'
            ? rfqsToConversionRate(subConvertedRfqs)
            : subConvertedRfqs.length) ?? 0,
        rfqCount: subConvertedRfqs.length,
      }))
    }
  )

  return (
    <div className="h-full flex flex-col gap-y-2">
      <div className="flex flex-wrap gap-4 items-center justify-between">
        <ChartTabs chartTab={chartTab} update={update} />

        <div className="flex flex-wrap items-center gap-2">
          {date && (
            <Tag onRemove={() => update({ date: null })}>
              {chartTab === 'trend' && <>Week of</>} {date.toLocaleString(DateTime.DATE_MED)}
            </Tag>
          )}

          {orgId && (
            <Tag onRemove={() => update({ orgId: null })}>{orgById && orgById[orgId].name}</Tag>
          )}

          {creatorId && (
            <Tag onRemove={() => update({ creatorId: null })}>
              {creatorById && creatorById[creatorId].name}
            </Tag>
          )}
        </div>
      </div>

      <div className="h-full z-[1000]">
        <ResponsiveLine
          xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false, precision: 'day' }}
          yScale={{ type: 'linear' }}
          margin={{ top: 10, bottom: 30, left: 10, right: 10 }}
          pointColor={{ from: 'color' }}
          pointSymbol={pointSymbol}
          pointSize={days > 30 ? 4 : days > 10 ? 5 : 8}
          pointBorderWidth={days > 30 ? 1.5 : days > 10 ? 2 : 3}
          pointBorderColor={ChartM.BACKGROUND_COLOR}
          pointLabelYOffset={-12}
          colors={{ datum: 'color' }}
          enableGridX={false}
          enableGridY={false}
          axisLeft={null}
          axisBottom={{
            tickSize: 0,
            legend: 'Request Received Date',
            legendPosition: 'middle',
            legendOffset: 20,
            format: () => '',
          }}
          theme={{
            fontSize: 16,
            textColor: '#6B7280',
            axis: { legend: { text: { fontSize: 16, fill: gray700 } } },
            background: ChartM.BACKGROUND_COLOR,
          }}
          useMesh
          data={[
            ...(orgId || creatorId
              ? [{ id: 'filtered', data: filteredData, color: ChartM.getDataColor(1) }]
              : []),
            {
              id: 'all',
              data: unfilteredData,
              color: ChartM.getDataColor(0),
            },
          ]}
          tooltip={Tooltip}
          onClick={(point) => {
            const newDate = DateTime.fromJSDate(point.data.x as Date)
            update({ date: date && newDate.equals(date) ? null : newDate })
          }}
        />
      </div>
    </div>
  )
}

export default Chart
