import { PencilIcon } from '@heroicons/react/outline'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { CompanyDetails } from './types'

const OrgDetails = ({
  companyDetails,
  subscriptionDetails,
}: {
  companyDetails: CompanyDetails
  subscriptionDetails: {
    loading: boolean
    productName: string
    isProPlan: boolean
    upgradePlan: ReactNode
    userCanUpgradePlan: boolean
  }
}) => (
  <hgroup className="flex gap-x-2 py-3 items-center">
    {companyDetails.logoUrl ? (
      <img
        src={companyDetails.logoUrl}
        alt={`${companyDetails.name} logo url`}
        className="inline-block h-10 w-10 object-contain rounded-full bg-white border border-gray-300"
      />
    ) : (
      <Link
        to={companyDetails.updateHref}
        className="inline-flex shrink-0 h-10 w-10 rounded-full bg-white border border-gray-300 hover:border-gray-400 hover:bg-gray-50 items-center justify-center group transition duration-200"
      >
        <PencilIcon className="inline-block text-gray-700 h-4 w-4 group-hover:text-gray-900" />
      </Link>
    )}

    <div className="truncate">
      <h1 className="font-medium text-ellipsis overflow-hidden leading-tight">
        {companyDetails.name}
      </h1>

      <p className="text-sm flex gap-x-2 items-center">
        {subscriptionDetails.productName}{' '}
        {subscriptionDetails.loading
          ? ''
          : subscriptionDetails.isProPlan
          ? 'Pro'
          : subscriptionDetails.userCanUpgradePlan && subscriptionDetails.upgradePlan}
      </p>
    </div>
  </hgroup>
)

export default OrgDetails
