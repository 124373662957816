import {
  DeliveryMethod,
  OrderDetailsDocument,
  RequestForQuoteDocument,
  RequestForQuoteQuery,
  useUpdateQuoteMutation,
} from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import PriceV2 from '@/gf/components/inputs/PriceV2'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import MoneyM from '@/gf/modules/Money'
import { Maybe } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useWatch } from 'react-hook-form'
import * as Yup from 'yup'
import AddressC from '../../Address'
import { addressInput } from '../../QuoteBuilder'
import useQuoteTotals from '../../QuoteBuilder/useQuoteTotals'
import Summary from '../../Summary'
import ApprovePartialQuoteConfirmation from './ApprovePartialQuoteConfirmation'

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const validationSchema = (deliveryMethod: DeliveryMethod) =>
  Yup.object().shape({
    taxCost: Yup.number().nullable(),
    shippingCost:
      deliveryMethod === DeliveryMethod.Pickup
        ? Yup.number().nullable().optional()
        : Yup.number().nullable().required('Shipping Cost is required'),
    quoteNumber: Yup.string().nullable(),
  })

type FormValues = {
  taxCost: Maybe<number>
  shippingCost: Maybe<number>
  quoteNumber: Maybe<string>
}

interface QuotedRfq extends NonNullable<RequestForQuoteQuery['requestForQuote']> {
  quote: NonNullable<NonNullable<RequestForQuoteQuery['requestForQuote']>['quote']>
}

const ApprovePartialQuote = ({ rfq }: { rfq: QuotedRfq }) => {
  const [_, msgs] = useMsgs()
  const [confirmationOpen, confirmationToggle] = useToggle()
  const [approvePartialQuote, { loading: updateInProgress }] = useUpdateQuoteMutation({
    refetchQueries: [OrderDetailsDocument, RequestForQuoteDocument],
  })

  const { quote } = rfq

  const { control, formState, register, handleSubmit, getValues } = useForm<FormValues>({
    resolver: yupResolver(validationSchema(quote.deliveryMethod)),
    defaultValues: {
      quoteNumber: quote.quoteNumber,
      shippingCost: quote.shippingAmount ? MoneyM.toDecimal(quote.shippingAmount) : null,
      taxCost: quote.salesTax ? MoneyM.toDecimal(quote.salesTax) : null,
    },
  })
  const taxCost = useWatch({ name: 'taxCost', control })
  const shippingCost = useWatch({ name: 'shippingCost', control })

  const { shippingAddress, deliveryMethod } = quote

  const approvedItems = quote.lineItems.filter((i) => !i.rejectedAt) ?? []

  const discount = quote.discounts[0]?.percent ?? null
  const shippingAmount = shippingCost ? MoneyM.fromDecimal(shippingCost, 'USD') : null
  const taxAmount = taxCost ? MoneyM.fromDecimal(taxCost, 'USD') : zeroPrice

  const { totals } = useQuoteTotals({
    items: approvedItems.map((li) => ({
      unitPrice: MoneyM.toDecimal(li.unitPrice),
      quantity: li.quantity,
    })),
    taxAmount,
    shippingAmount,
    customerDiscount: discount,
    customerFeeRate: quote.customerFeeRate,
  })

  const onSubmit = () => {
    const values = getValues()

    approvePartialQuote({
      variables: {
        storeOrderId: quote.id,
        taxCost: values.taxCost,
        shippingCost: quote.deliveryMethod === DeliveryMethod.Shipping ? values.shippingCost : null,
        quoteNumber: values.quoteNumber,
        items: approvedItems.map((item) => ({
          partNumber: item.product.mpn,
          description: item.product.name,
          quantity: item.quantity,
          unitPrice: MoneyM.toDecimal(item.unitPrice),
          inStock: item.inStock === null ? true : !!item.inStock,
          availabilityDate: item.availableAt,
          externalId: item.externalId,
          taskNumber: item.taskNumber,
          rfqPartId: item.rfqPart?.id ?? null,
        })),
        notes: null,
        pickupAddress:
          quote.deliveryMethod === DeliveryMethod.Pickup && quote.pickupAddress
            ? addressInput(quote.pickupAddress)
            : null,
        shippingAddress: addressInput(quote.shippingAddress),
        deliveryMethod: quote.deliveryMethod,
        discountPercent: discount,
      },
    })
      .then(confirmationToggle.off)
      .catch((err) => {
        console.error(err)
        msgs.addUnknownError()
      })
  }

  const showConfirmation = () => confirmationToggle.on()

  return (
    <>
      <ApprovePartialQuoteConfirmation
        totals={totals}
        shippingCost={shippingCost}
        taxCost={rfq.requestDetails || !MoneyM.isZero(taxAmount) ? taxAmount : null}
        discount={discount}
        deliveryMethod={quote.deliveryMethod}
        open={confirmationOpen}
        onClose={confirmationToggle.off}
        onSubmit={onSubmit}
        submitInProgress={updateInProgress}
      />

      <form onSubmit={handleSubmit(showConfirmation)} className="space-y-4">
        {deliveryMethod === DeliveryMethod.Shipping && (
          <Box className="shadow-base p-6">
            {shippingAddress && <AddressC address={shippingAddress} title="Shipping Address" />}

            <Controller
              name="shippingCost"
              control={control}
              render={({ field }) => (
                <Field
                  label="Shipping cost"
                  error={formState.errors?.shippingCost?.message}
                  className="mt-4"
                >
                  <PriceV2
                    ref={field.ref}
                    price={field.value !== null ? MoneyM.fromDecimal(field.value, 'USD') : null}
                    onChange={(value) => field.onChange(value ? MoneyM.toDecimal(value) : null)}
                  />
                </Field>
              )}
            />
          </Box>
        )}

        <Box className="shadow-base p-6 space-y-4">
          <Controller
            name="taxCost"
            control={control}
            render={({ field }) => (
              <Field label="Sales tax (optional)">
                <PriceV2
                  ref={field.ref}
                  price={field.value !== null ? MoneyM.fromDecimal(field.value, 'USD') : null}
                  onChange={(value) => field.onChange(value ? MoneyM.toDecimal(value) : null)}
                />
              </Field>
            )}
          />

          <Field label="Quote number (optional)">
            <TextInput {...register('quoteNumber')} />
          </Field>

          <Summary
            total={totals.total}
            subtotal={totals.subtotal}
            discount={totals.discount}
            discountPercent={discount}
            shippingCost={shippingAmount}
            taxCost={rfq.requestDetails || !MoneyM.isZero(taxAmount) ? taxAmount : null}
            customerFee={totals.customerFee}
            refunded={MoneyM.fromInt(0, 'USD')}
            showShippingCost={deliveryMethod === DeliveryMethod.Shipping}
          />

          <div className="flex gap-x-4">
            <Action.P
              type="submit"
              disabled={updateInProgress}
              performing={updateInProgress}
              className="w-full"
            >
              Accept Changes
            </Action.P>
          </div>
        </Box>
      </form>
    </>
  )
}

export default ApprovePartialQuote
