import AnimatedHamburguerIcon from '@/gf/components/AnimatedHamburguerIcon'
import useToggle from '@/gf/hooks/useToggle'
import type { Breadcrumbs } from '@/types'
import classNames from 'classnames'
import BreadcrumbsC from './RocketLayout/Breadcrumbs'
import HeaderActionsC from './RocketLayout/HeaderActions'
import Messages from './RocketLayout/Messages'
import NavMenu from './RocketLayout/NavMenu'
import Search, { SearchResultsRenderFn } from './RocketLayout/Search/Search'
import useSearch from './RocketLayout/Search/useSearch'
import TrialBanner from './RocketLayout/TrialBanner'
import type {
  HeaderActions,
  MenuAction,
  NavItemGroup,
  SubscriptionDetails,
  UserDetails,
} from './RocketLayout/types'
import { RefObject } from 'react'

const RocketLayout = ({
  orgDetails,
  children,
  header,
  contentClassName,
  breadcrumbs,
  subscription,
  user,
  navItems,
  navAction,
  headerActions,
  renderSearch,
  contentRef,
}: {
  orgDetails: JSX.Element
  children: React.ReactNode
  header?: React.ReactNode
  contentClassName?: string
  breadcrumbs?: Breadcrumbs
  user: UserDetails
  subscription: SubscriptionDetails
  className: string
  navItems: NavItemGroup[]
  navAction: MenuAction
  headerActions: HeaderActions
  renderSearch: SearchResultsRenderFn
  contentRef?: RefObject<HTMLElement>
}) => {
  const search = useSearch()
  const mobileSearch = useSearch()
  const [mobileMenuOpen, mobileMenuToggle] = useToggle()

  return (
    <div className="h-screen border-t-[0.25rem] mdplus:border-t-[0.5rem] border-orange-500 flex flex-col mdplus:flex-row bg-gray-100">
      <div className="block mdplus:hidden mdplus:bg-red-500">
        <header className="bg-white flex shadow-sm px-3 py-1.5 overflow-x-hidden">
          <button type="button" className="z-[2002]" onClick={mobileMenuToggle.toggle}>
            <AnimatedHamburguerIcon active={mobileMenuOpen} />
          </button>

          <Search fullWidth {...mobileSearch} renderSearchResults={renderSearch} />

          <div
            className={classNames(
              'flex-grow flex justify-end gap-x-2 md:gap-x-6 transition-all duration-300 opacity-100 flex-shrink-0',
              mobileSearch.active && 'hidden opacity-0'
            )}
          >
            <HeaderActionsC actions={headerActions} userName={user.name} />
          </div>
        </header>

        <TrialBanner subscriptionDetails={subscription} />
      </div>

      <NavMenu
        menuAction={navAction}
        navItems={navItems}
        className={classNames(
          'w-56 bg-white flex-shrink-0 pt-10 mdplus:pt-4 pb-4 px-4 shadow-md flex flex-col fixed mdplus:static left-0 mdplus:left-auto inset-y-0 mdplus:inset-y-auto duration-500 mdplus:translate-x-0 z-[2001]',
          mobileMenuOpen ? 'translate-x-0' : '-translate-x-56'
        )}
      >
        {orgDetails}
      </NavMenu>

      <main
        className={classNames(
          'relative grow flex flex-col overflow-y-auto scroll-smooth',
          contentClassName
        )}
        ref={contentRef}
      >
        <div className="hidden mdplus:block sticky top-0 z-[2000]">
          <TrialBanner subscriptionDetails={subscription} />

          <div className="bg-white shadow-base flex items-center py-2 px-8 gap-x-6">
            <Search {...search} renderSearchResults={renderSearch} />
            <HeaderActionsC actions={headerActions} userName={user.name} />
            {header}
          </div>
        </div>

        {breadcrumbs && <BreadcrumbsC breadcrumbs={breadcrumbs} />}
        <Messages />
        {children}
      </main>
    </div>
  )
}

export default RocketLayout
