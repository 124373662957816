import Tag from '@/gf/components/Reports/Tag'
import useChartPointSymbol from '@/gf/hooks/useChartPointSymbol'
import Money from '@/gf/modules/Money'
import { PointTooltip, ResponsiveLine } from '@nivo/line'
import map from 'lodash/map'
import { DateTime } from 'luxon'
import { ChartTabs } from '../ChartTabs'
import { StoreOrder, filterStoreOrdersReceivedBetween, sumStoreOrdersTotal } from '../PartsSales'
import { useContext } from './Context'
import ChartM from '@/gf/modules/Chart'

const gray700 = 'rgb(55, 65, 81)'

const Tooltip: PointTooltip = ({ point }) => {
  const { chartTab } = useContext()
  const date = DateTime.fromJSDate(point.data.x as Date)
  const total = Money.fromInt(point.data.y as number, 'USD')

  return (
    <div
      key={point.id}
      className="text-sm px-3 py-2 flex flex-col gap-y-1 bg-white border-gray-300 border rounded shadow-md"
    >
      <span>
        {chartTab === 'trend'
          ? `Week of ${date.toLocaleString(DateTime.DATE_MED)}`
          : date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
      </span>

      <span className="font-medium">{Money.format(total, { maximumFractionDigits: 0 })}</span>
    </div>
  )
}

const Chart = () => {
  const {
    storeOrders: unfilteredStoreOrders,
    filteredStoreOrders,
    orgById,
    fromDate,
    toDate,
    date,
    orgId,
    mpn,
    chartTab,
    ranges,
    update,
  } = useContext()

  const pointSymbol = useChartPointSymbol(date || undefined)
  const days = toDate.diff(fromDate).as('days')

  const [unfilteredData, filteredData] = [unfilteredStoreOrders, filteredStoreOrders].map(
    (storeOrders) => {
      if (!storeOrders) return []

      // { "2024-02-24": [ storeOrder, ... ], ... ]
      const storeOrdersByIsoDate = ranges.reduce<Record<string, StoreOrder[]>>(
        (acc, [start, end]) => {
          const subStoreOrders = filterStoreOrdersReceivedBetween(storeOrders, [start, end])
          return { ...acc, [start.toISODate()]: subStoreOrders }
        },
        {}
      )

      // [ { x: "2024-02-24", y: 175.48 }, ... ]
      return map(storeOrdersByIsoDate, (subStoreOrders, isoDate) => ({
        x: isoDate,
        y: sumStoreOrdersTotal(subStoreOrders).amount,
      }))
    }
  )

  return (
    <div className="h-full flex flex-col gap-y-2">
      <div className="flex flex-wrap gap-4 items-center justify-between">
        <ChartTabs chartTab={chartTab} update={update} />

        <div className="flex flex-wrap items-center gap-2">
          {date && (
            <Tag onRemove={() => update({ date: null })}>
              {chartTab === 'trend' && <>Week of</>} {date.toLocaleString(DateTime.DATE_MED)}
            </Tag>
          )}

          {orgId && (
            <Tag onRemove={() => update({ orgId: null })}>{orgById && orgById[orgId].name}</Tag>
          )}

          {mpn && <Tag onRemove={() => update({ mpn: null })}>MPN: {mpn}</Tag>}
        </div>
      </div>

      <div className="h-full z-[1000]">
        <ResponsiveLine
          xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false, precision: 'day' }}
          yScale={{ type: 'linear' }}
          margin={{ top: 10, bottom: 30, left: 10, right: 10 }}
          pointColor={{ from: 'color' }}
          pointSymbol={pointSymbol}
          pointSize={days > 30 ? 4 : days > 10 ? 5 : 8}
          pointBorderWidth={days > 30 ? 1.5 : days > 10 ? 2 : 3}
          pointBorderColor={ChartM.BACKGROUND_COLOR}
          pointLabelYOffset={-12}
          colors={{ datum: 'color' }}
          enableGridX={false}
          enableGridY={false}
          axisLeft={null}
          axisBottom={{
            tickSize: 0,
            legend: 'Request Received Date',
            legendPosition: 'middle',
            legendOffset: 20,
            format: () => '',
          }}
          theme={{
            fontSize: 16,
            textColor: '#6B7280',
            axis: { legend: { text: { fontSize: 16, fill: gray700 } } },
            background: ChartM.BACKGROUND_COLOR,
          }}
          useMesh
          data={[
            ...(orgId || mpn
              ? [{ id: 'filtered', data: filteredData, color: ChartM.getDataColor(1) }]
              : []),
            {
              id: 'all',
              data: unfilteredData,
              color: ChartM.getDataColor(0),
            },
          ]}
          tooltip={Tooltip}
          onClick={(point) => {
            const newDate = DateTime.fromJSDate(point.data.x as Date)
            update({ date: date && newDate.equals(date) ? null : newDate })
          }}
        />
      </div>
    </div>
  )
}

export default Chart
