import { Maybe } from '@/types'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import TextInput from './forms/TextInput'
import { twMerge } from 'tailwind-merge'

const roundClosestHalfHour = (dt: DateTime) => {
  const rounded = dt.set({ minute: 0, second: 0, millisecond: 0 })

  if (dt.minute === 30) {
    return dt.set({ second: 0, millisecond: 0 })
  }

  if (dt.minute > 30) {
    return rounded.plus({ hour: 1 })
  }

  return rounded
}

const isSameDateTime = (d1: DateTime, d2: DateTime) => d1.diff(d2, 'minute').as('minute') === 0

export const DATE_FORMAT = 'yyyy-MM-dd'
export const TIME_FORMAT = 'h:mm a'

const thirtyMinMs = 30 * 60 * 1000 // 30 min
const oneDayMs = 24 * 60 * 60 * 1000

const iterations = oneDayMs / thirtyMinMs

let controlDate = DateTime.now().startOf('day')
const options: string[] = []
for (let i = 1; i <= iterations; i += 1) {
  options.push(controlDate.toFormat(TIME_FORMAT))
  controlDate = controlDate.plus({ milliseconds: thirtyMinMs + 1 })
}

type State = { date?: string; time?: string }

const DateTimeSelector = ({
  value,
  onChange,
  disabled,
  required,
}: {
  value: Maybe<DateTime>
  onChange: (newValue: Maybe<DateTime>) => void
  disabled?: boolean
  required?: boolean
}) => {
  const [state, setState] = useState<State>({ date: '', time: '' })
  const update = (changes: Partial<State>) => setState((prev) => ({ ...prev, ...changes }))

  const selectedDate = useMemo(
    () =>
      state.date && state.time
        ? DateTime.fromFormat(`${state.date} ${state.time}`, `${DATE_FORMAT} ${TIME_FORMAT}`)
        : null,
    [state]
  )

  useEffect(() => {
    if (value && selectedDate && isSameDateTime(value, selectedDate)) {
      return
    }

    onChange(selectedDate)
  }, [value, selectedDate])

  useEffect(() => {
    if (value) {
      const rounded = roundClosestHalfHour(value)
      setState({ date: rounded.toFormat(DATE_FORMAT), time: rounded.toFormat(TIME_FORMAT) })
    }
  }, [value])

  return (
    <div className="flex gap-x-2">
      <TextInput
        value={state.date}
        type="date"
        onChange={(e) =>
          update({
            date: e.target.valueAsDate
              ? DateTime.fromJSDate(e.target.valueAsDate)
                  .toUTC()
                  .startOf('day')
                  .toFormat(DATE_FORMAT) ?? undefined
              : undefined,
          })
        }
        required={required}
        className="w-auto"
      />
      <select
        value={state.time}
        onChange={(e) => update({ time: e.target.value })}
        className={twMerge(
          'border border-gray-300 placeholder:text-gray-400 text-sm text-gray-900 focus:border-blue-600 focus:ring-blue-600 px-3 py-2 rounded-md w-28',
          disabled && 'bg-gray-50'
        )}
        required={required}
      >
        {options.map((o) => (
          <option key={o} value={o}>
            {o}
          </option>
        ))}
      </select>
    </div>
  )
}

export default DateTimeSelector
