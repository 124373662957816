import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
  PaymentMethod,
  Product,
  ShipmentItem,
  ShipmentOrderItemsQuery,
  StoreOrder,
} from '../../../_gen/gql'

import FormM from '@/gf/modules/Form'

import Action from '@/gf/components/Action'
import AddressC, { AddressT } from '@/gf/components/Address'
import Field from '@/gf/components/Field'
import DateTimeInput from '@/gf/components/inputs/DateTime'
import QuantityInput from '@/gf/components/inputs/Quantity'
import ShippingCarrierInput from '@/gf/components/next/ShippingCarrierInput'
import Tabs from '@/gf/components/next/Tabs'
import RedAlert from '@/gf/components/RedAlert'
import TextArea from '@/gf/components/TextArea'
import TextField from '@/gf/components/TextField'
import VendorDeliveryInfo from './VendorDeliveryInfo'

type LineItem = Exclude<ShipmentOrderItemsQuery['storeOrder'], null>['lineItems'][number]

const formSchema = Yup.object().shape({
  notes: Yup.string().nullable(),
  trackingNumber: Yup.string().trim().label('Tracking Number').required().nullable(),
  shippingCarrier: Yup.string().label('Shipping Carrier').required().nullable(),
  shipmentItems: Yup.array(
    Yup.object().shape({
      lineItemId: Yup.string().required(),
      quantity: Yup.number()
        .required()
        .min(0)
        .integer()
        .typeError('Quantity must be a number')
        .label('Quantity'),
    })
  ),
})

type Form = {
  notes: string | null
  trackingNumber: string | null
  shippingCarrier: string | null
  shipmentItems: {
    lineItemId: string
    quantity: number
  }[]
}

type ShippingTab = 'Shipping Carrier' | 'Delivery'

const defaultValues = {
  notes: '',
  trackingNumber: null,
  shippingCarrier: null,
  shipmentItems: [],
}

type VendorDeliveryForm = {
  outForDeliveryAt: DateTime
  note: string | null
}

type StoreOrderT = Pick<StoreOrder, 'state' | 'paymentMethod'> & {
  shippingAddress: AddressT | null
  shipments: { items: Pick<ShipmentItem, 'lineItemId' | 'quantity'>[] }[]
  lineItems: (Pick<LineItem, 'id' | 'quantity'> & {
    product: Pick<Product, 'name'> & { mpn: string | null }
  })[]
}

const CreateShipmentForm = ({
  storeOrder,
  performing,
  error,
  onSubmit,
  onSubmitVendorDelivery,
  onClose,
}: {
  storeOrder: StoreOrderT
  performing: boolean
  error?: string | null
  onSubmit: (values: Form) => unknown
  onSubmitVendorDelivery: (values: VendorDeliveryForm) => unknown
  onClose: () => unknown
}) => {
  const [selectedTabName, setSelectedTabName] = useState<ShippingTab>('Shipping Carrier')
  const { control, setValue, register, handleSubmit, formState, setError, watch } = useForm<Form>({
    defaultValues,
    resolver: yupResolver(formSchema),
  })
  const notes = watch('notes')
  const shipmentItems = watch('shipmentItems')

  const shippedQuantityByLineItemId = storeOrder.shipments.reduce<Record<string, number>>(
    (acc, shipment) =>
      shipment.items.reduce(
        (subAcc, item) => ({
          ...subAcc,
          [item.lineItemId]: (subAcc[item.lineItemId] || 0) + item.quantity,
        }),
        acc
      ),
    {}
  )

  const applyUnshippedQuantity = (lineItem: StoreOrderT['lineItems'][number]) => {
    if (!shippedQuantityByLineItemId) throw new Error()

    const shippedQuantity = shippedQuantityByLineItemId[lineItem.id] || 0

    // Ensures unshipped is still 0 if somehow more items were shipped than the quantity.
    const unshippedQuantity =
      shippedQuantity > lineItem.quantity ? 0 : lineItem.quantity - shippedQuantity

    return { ...lineItem, unshippedQuantity }
  }

  const lineItems = storeOrder.lineItems
    .map(applyUnshippedQuantity)
    .filter((li) => li.unshippedQuantity > 0)

  useEffect(() => {
    if (lineItems) {
      setValue(
        'shipmentItems',
        lineItems.map((li) => ({ lineItemId: li.id, quantity: li.unshippedQuantity }))
      )
    }
  }, [!lineItems])

  const [outForDeliveryAt, setOutForDeliveryAt] = useState(DateTime.now())

  // Don't allow delivery if items have already been shipped
  // Only allow delivery for direct pay orders
  const canDeliver =
    storeOrder.paymentMethod === PaymentMethod.Direct && storeOrder.shipments.length === 0

  return (
    <>
      {canDeliver && (
        <div className="px-4 mt-4 flex flex-row gap-x-4">
          <Tabs
            tabs={[{ name: 'Shipping Carrier' }, { name: 'Delivery' }]}
            selectedTabName={selectedTabName}
            onTabSelect={(tabName) => setSelectedTabName(tabName as ShippingTab)}
          />
        </div>
      )}

      <div className="mt-6 mb-4">
        {selectedTabName === 'Shipping Carrier' ? (
          <div className="flex flex-col gap-y-4 text-gray-900">
            <p className="px-4">Enter the tracking details for the customer&apos;s shipment.</p>

            {storeOrder.shippingAddress && (
              <div className="bg-amber-50 px-4 py-2">
                <h3 className="text-slate-700 font-semibold pb-2">Shipping Address</h3>
                <AddressC className="text-sm" address={storeOrder.shippingAddress} />
              </div>
            )}

            <form
              onSubmit={handleSubmit((form) => {
                const shippedItemsCount = form.shipmentItems.filter((si) => si.quantity > 0).length
                if (shippedItemsCount === 0) {
                  setError('shipmentItems', { message: 'Cannot create a shipment with no items.' })
                  return
                }
                onSubmit(form)
              })}
              className="flex flex-col gap-y-3"
            >
              <h3 className="text-slate-600 font-semibold px-4">Shipped Items</h3>
              {shipmentItems.map((shipmentItem, i) => (
                <div
                  key={lineItems[i].id}
                  className={classNames(
                    'px-4 pb-2 flex border-b',
                    i === shipmentItems.length - 1 && 'border-b-0 pb-0'
                  )}
                >
                  <div className="flex flex-col grow">
                    <div className="flex flex-row justify-between items-center gap-x-2">
                      <div className="flex-grow">
                        <p className="font-medium">{lineItems[i].product?.name}</p>
                        <p className="text-sm">Part #: {lineItems[i].product?.mpn}</p>
                      </div>
                      <div className="flex items-center gap-x-2 whitespace-nowrap">
                        <div className="w-20">
                          <QuantityInput
                            min={0}
                            max={lineItems[i].unshippedQuantity}
                            value={shipmentItem.quantity ?? 1}
                            onChange={(e) =>
                              setValue(`shipmentItems.${i}.quantity`, e.target.valueAsNumber, {
                                shouldValidate: FormM.shouldValidate(formState.errors),
                              })
                            }
                          />
                        </div>
                        of {lineItems[i].unshippedQuantity}
                      </div>
                    </div>
                    {formState.errors.shipmentItems && formState.errors.shipmentItems[i] && (
                      <div className="flex gap-1 items-center">
                        <ExclamationCircleIcon
                          className="block h-4 w-4 flex-shrink-0 text-red-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-red-500 leading-4">
                          {formState.errors.shipmentItems[i]?.message ??
                            formState.errors.shipmentItems[i]?.quantity?.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {formState.errors.shipmentItems?.message && (
                <div className="flex gap-1 items-center px-4">
                  <ExclamationCircleIcon
                    className="block h-4 w-4 flex-shrink-0 text-red-500"
                    aria-hidden="true"
                  />
                  <span className="text-sm text-red-500 leading-4">
                    {formState.errors.shipmentItems?.message}
                  </span>
                </div>
              )}

              <div className="bg-slate-50 p-4 space-y-3">
                <h3 className="text-slate-600 font-semibold">Tracking Details</h3>
                <div className="grid grid-cols-2 gap-x-4">
                  <Controller
                    control={control}
                    name="shippingCarrier"
                    render={({ field, fieldState }) => (
                      <Field label="Shipping Carrier" errorText={fieldState.error?.message}>
                        <ShippingCarrierInput
                          value={field.value}
                          onChange={(carrier) =>
                            setValue('shippingCarrier', carrier, {
                              shouldValidate: FormM.shouldValidate(formState.errors),
                            })
                          }
                        />
                      </Field>
                    )}
                  />
                  <TextField
                    label="Tracking Number"
                    {...register('trackingNumber')}
                    placeholder="i.e. 134509253094, 1ZP92387"
                    errorText={formState.errors?.trackingNumber?.message}
                  />
                </div>
              </div>

              <div className="px-4 flex flex-col gap-y-4">
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <TextArea
                      placeholder="Include any additional comments here to send in the notification."
                      rows={4}
                      value={field.value ?? ''}
                      onChange={(e) => field.onChange(e.target.value ?? null)}
                    />
                  )}
                />
                {error && <RedAlert title={error} />}
                <div className="flex justify-end gap-x-4">
                  <Action.S type="button" onClick={onClose}>
                    Cancel
                  </Action.S>
                  <Action.P type="submit" performing={performing} disabled={performing}>
                    Create Shipment
                  </Action.P>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="flex flex-col gap-y-4 text-gray-900">
            <VendorDeliveryInfo className="-mt-2 mx-4" />

            {storeOrder.shippingAddress && (
              <div className="px-4 py-2 bg-amber-50">
                <h3 className="text-slate-700 font-semibold pb-2">Shipping Address</h3>
                <AddressC className="text-sm" address={storeOrder.shippingAddress} />
              </div>
            )}

            <div className="px-4">
              <p className="pb-2 font-medium">When did this order go out for delivery?</p>
              <div className="flex gap-x-2 items-center">
                <DateTimeInput
                  dateTime={outForDeliveryAt}
                  updateDateTime={(ra) => ra && setOutForDeliveryAt(ra)}
                  required
                />
                <p className="text-sm text-slate-600 italic">(Approximate day & time)</p>
              </div>
            </div>

            <div className="px-4 flex flex-col gap-y-4">
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <TextArea
                    placeholder="Include any additional comments here to send in the notification."
                    rows={4}
                    value={field.value ?? ''}
                    onChange={(e) => field.onChange(e.target.value ?? null)}
                  />
                )}
              />
              {error && <RedAlert title={error} />}
              <div className="flex justify-end gap-x-4">
                <Action.S type="button" onClick={onClose}>
                  Cancel
                </Action.S>
                <Action.P
                  onClick={() =>
                    onSubmitVendorDelivery({
                      outForDeliveryAt,
                      note: notes,
                    })
                  }
                  performing={performing}
                  disabled={performing}
                >
                  Out for Delivery
                </Action.P>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CreateShipmentForm
