import {
  useProductsListQuery,
  useUploadProgressQuery,
  useUpsertProgressSubscription,
} from '@/dealers/_gen/gql'
import ExportProductsModal from '@/dealers/components/ExportProducts'
import Frame from '@/dealers/components/Frame'
import UploadProducts from '@/dealers/components/UploadProducts'
import useApolloWsClient from '@/dealers/hooks/useApolloWsClient'
import useSession from '@/dealers/hooks/useSession'
import Urls from '@/dealers/modules/Urls'
import A from '@/gf/components/A'
import Box from '@/gf/components/Box'
import Button from '@/gf/components/ButtonOld'
import Layout from '@/gf/components/Layout'
import Link from '@/gf/components/Link'
import Page from '@/gf/components/Page'
import Pagination, { PaginationGhost } from '@/gf/components/Pagination'
import RedAlert from '@/gf/components/RedAlert'
import SearchInput from '@/gf/components/SearchInput'
import { Table, Tbody, Td, TdGhost, Th, Thead, Tr } from '@/gf/components/Table'
import usePage from '@/gf/hooks/usePage'
import useToggle from '@/gf/hooks/useToggle'
import { trackSearch } from '@/gf/modules/Analytics'
import Money from '@/gf/modules/Money'
import Time from '@/gf/modules/Time'
import debounce from 'lodash/debounce'
import { useRef, useState } from 'react'
import AvailabilityStatus from '../components/Products/AvailabilityStatus'

const TrGhost = () => (
  <Tr>
    <TdGhost className="w-0" ghostClassName="w-32 min-w-32 bg-gray-300" />
    <TdGhost ghostClassName="w-32 min-w-32 bg-gray-300" />
    <TdGhost ghostClassName="w-32 min-w-32 bg-gray-300" />
    <TdGhost ghostClassName="w-32 min-w-32 bg-gray-300" />
    <TdGhost ghostClassName="w-32 min-w-32 bg-gray-300" />
  </Tr>
)

const Products = () => {
  const [showUpload, uploadToggle] = useToggle()
  const [showExport, exportToggle] = useToggle()
  const { store, featureFlags } = useSession()
  const [page, setPage] = usePage()
  const [search, setSearch] = useState('')

  const { refetch, data: progressData } = useUploadProgressQuery({
    variables: {
      storeId: store.id,
    },
  })

  const progressComplete = progressData?.uploadProgress ?? null

  const {
    refetch: refetchProducts,
    data,
    error,
  } = useProductsListQuery({
    variables: {
      page,
      pageSize: 20,
      search,
    },
    fetchPolicy: 'network-only',
    onCompleted: (resultData) => {
      if (search) {
        trackSearch(search, 'products_page', {
          resultsFound: resultData.products.pagination.totalResults,
        })
      }
    },
  })

  useUpsertProgressSubscription({
    variables: { storeId: store.id },
    client: useApolloWsClient(),
    onData: () => {
      refetchProducts()
      refetch()
    },
  })

  const onSearchChanged = (searchTerm: string) => {
    setPage(1)
    setSearch(searchTerm)
  }
  const onSearchChangedDebounced = useRef(debounce(onSearchChanged, 300)).current

  const onCloseUpload = () => {
    refetch()
    uploadToggle.off()
  }

  const actions = [
    <Link.P to={Urls.createProduct()} key="create-product">
      Create Product
    </Link.P>,
  ]
  const actionsNext = featureFlags.hideDealerUpload
    ? actions
    : [
        <Button
          type="button"
          primary={false}
          onClick={() => exportToggle.on()}
          key="export-products"
        >
          Export
        </Button>,
        <Button
          type="button"
          primary={false}
          onClick={() => uploadToggle.on()}
          key="upload-products"
        >
          Upload
        </Button>,
      ].concat(actions)

  return (
    <Frame
      breadcrumbs={{
        copy: 'Back to Dashboard',
        crumbs: [{ name: 'Catalog', href: Urls.products() }],
      }}
    >
      <Page title="Catalog" actionsNext={actionsNext}>
        <Layout>
          <ExportProductsModal show={showExport} close={exportToggle.off} label="catalog" />
          <UploadProducts show={showUpload} close={onCloseUpload} />
          <Layout.Section className="pb-8" type="full">
            {error ? (
              <RedAlert className="border border-red-600" title="Error, please contact support." />
            ) : (
              <>
                <Box className="!rounded-sm">
                  {(progressComplete === 0 || progressComplete) && (
                    <div className="px-4 py-4">
                      <h4 className="sr-only">Status</h4>
                      <p className="text-sm text-gray-900">Importing parts catalog...</p>
                      <div className="mt-6" aria-hidden="true">
                        <div className="overflow-hidden rounded-full bg-gray-200">
                          <div
                            className="h-2 rounded-full bg-indigo-600"
                            style={{ width: `${progressComplete}%` }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="pt-3 pb-1 px-4 bg-gray-50">
                    <SearchInput
                      initialValue={search}
                      onChange={onSearchChangedDebounced}
                      placeholder="type to search for mpn, name, description..."
                    />
                  </div>

                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Mpn</Th>
                        <Th>Sale Price</Th>
                        <Th>Availability</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {!data ? (
                        <>
                          <TrGhost />
                          <TrGhost />
                          <TrGhost />
                          <TrGhost />
                          <TrGhost />
                          <TrGhost />
                        </>
                      ) : data.products.entries.length === 0 ? (
                        <Tr>
                          <Td className="text-sm text-slate-700">
                            No products found
                            {!!search && (
                              <>
                                {' '}
                                for <span className="font-medium text-slate-900">{search}</span>
                              </>
                            )}
                          </Td>
                        </Tr>
                      ) : (
                        data.products.entries.map((product) => (
                          <Tr key={product.id}>
                            <Td className="w-0">
                              <A.T className="text-base" href={Urls.product(product.id)}>
                                {product.name}
                              </A.T>
                              <p className="mt-1 text-xs text-slate-400 italic">
                                Created {Time.toString(product.insertedAt)}
                              </p>
                            </Td>
                            <Td>{product.mpn}</Td>
                            <Td>{Money.format(Money.fromDecimal(product.salePrice, 'USD'))}</Td>
                            <Td pad={false}>
                              {product.availability ? (
                                <AvailabilityStatus
                                  availability={product.availability}
                                  leadTime={product.leadTime}
                                  leadTimeDate={product.leadTimeDate}
                                />
                              ) : (
                                <span className="text-xs italic text-slate-400">
                                  Unknown availability status
                                </span>
                              )}
                            </Td>
                          </Tr>
                        ))
                      )}
                    </Tbody>
                  </Table>
                </Box>

                {!data ? (
                  <PaginationGhost />
                ) : (
                  <Pagination
                    page={page}
                    updatePage={setPage}
                    pagination={data?.products.pagination}
                  />
                )}
              </>
            )}
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  )
}

export default Products
